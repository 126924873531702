const translations = JSON.parse(window._t)['json'];
const iso = JSON.parse(window._t)['iso'];

import _ from 'lodash'

const translate = (key, replacements = {}) => {
  let lang = localStorage.getItem('build-spa-lang') || _.head(iso);
  if (iso.length == 1) {
    lang = _.head(iso)
    localStorage.setItem('build-spa-lang', lang)
  }

  let word = translations[lang];
  let fallback_locale = document.querySelector('meta[name="fallback_locale"]') || null;

  const getAltValue = (object, keys) => keys.split('.').reduce((o, k) => (o || {})[k], object);

  const keys = key.split('.');

  for (let i in keys) {
    try {
      word = word[keys[i]];
      if (word === undefined) {
        if (fallback_locale.content) {
          word = getAltValue(translations[fallback_locale.content], key) || key;
        } else {
          word = key;
        }
        break;
      }
    } catch (e) {
      word = key;
      break;
    }
  }
  for (let i in replacements) {
    word = word.replace(`:${i}`, replacements[i]);
  }
  return word;

}

export {
  translate
}