<template>
    <BaseLayout :titleposa="titleposa()" :title="$attrs.title" :subtitle="$attrs.subtitle" :steps="$attrs.steps" :variant="'default'"  :prev='prevStep()' :next="nextStep()">
        <div class="w-100 form-template-1 h-100 d-flex flex-column">
            <div class="row h-100 scroll">
                <div class="col-12 d-flex align-items-center">
                    <div class="w-100 row" v-if="$store.state.pose_type.id == 0">
                        <div v-id="$store.state.magatello" class="col-lg-4 mb-lg-3 mb-5 col-12 text-center">
                            <p class="text--lg text-uppercase fw--bold fc--black">{{translate('steps.livingproduct.decking')}}</p>
                            <img :src="$store.state.magatello.image" class="d-block mx-auto" style="height:160px;">
                        </div>
                        <div class="col-lg-4 mb-lg-3 mb-5 col-12 text-center">
                            <p class="text--lg text-uppercase fw--bold fc--black">{{translate('steps.livingproduct.title')}}</p>
                            <img :src="$store.state.livingproduct.image" class="d-block mx-auto mt-3 mb-3 pt-3"  style="height:100px;">
                            <p class="text--sm  mb-1">
                                <span class="text--md text-uppercase fw--bold fc--black d-block">{{ $store.state.livingproduct.descrizione}}</span>
                                Lunghezza: {{$store.state.tile[0].value+'mm'}}<br />
                                Larghezza: {{$store.state.tile[1].value+'mm'}}<br />
                                Spessore: {{$store.state.tile[2].value+'mm'}}
                            </p>
                        </div>
                        <div class="col-lg-4 mb-5 mb-lg-3 col-12 text-center">
                            <p class="text--lg text-uppercase fw--bold fc--black">{{translate('decking.summary.area')}}</p>
                            <img :src="$store.state.form.image" class="d-block mx-auto" style="height:160px;">
                            <p class="text--sm mb-0">
                                <span v-for="area_size in $store.state.area.area_sizes">&nbsp;&nbsp;{{area_size.name+': '+area_size.value+'m'}}&nbsp;&nbsp;</span>
                            </p>
                        </div>
                        <div v-if="$store.state.pose_direction != null" class="col-lg-4 mb-lg-3 mb-5 col-12 text-center mt-4">
                            <p class="text--lg text-uppercase fw--bold fc--black">{{translate('steps.pose.title')}}</p>
                            <img :src="$store.state.pose_direction.image" class="d-block mx-auto" style="height:160px;">
                            <p class="text--sm  mb-1">{{$store.state.pose_direction.description}}</p>
                        </div>
                        <div class="col-lg-4 mb-lg-3 mb-5 col-12 text-center mt-4">
                            <p class="text--lg text-uppercase fw--bold fc--black">{{translate('decking.summary.altezze')}}</p>
                            <img :src="require('@/assets/images/png/imgaltezze.png')" class="d-block mx-auto mt-3 mb-3 pt-3"  style="height:130px;">
                            <p class="text--sm mb-0">{{translate('decking.summary.height_a')}} {{$store.state.area.height_a}} mm</p>
                            <p class="text--sm mb-0">{{translate('decking.summary.height_b')}} {{$store.state.area.height_b}} mm</p>
                            <p class="text--sm mb-0">{{translate('decking.summary.height_c')}} {{$store.state.area.height_c}} mm</p>
                            <p class="text--sm mb-0">{{translate('decking.summary.height_d')}} {{$store.state.area.height_d}} mm</p>
                        </div>
                        <div class="col-lg-4 col-12 text-center mt-4">
                            <!-- <p class="text--lg text-uppercase fw--bold fc--black">{{translate('decking.summary.crea')}}</p>
                            <p class="text--sm">{{translate('decking.summary.creatext')}}</p> -->
                            <p class="text--lg text-uppercase fw--bold fc--black">{{translate('decking.summary.scarico')}}</p>
                            <img :src="require('@/assets/images/png/imgscarico.png')" class="d-block mx-auto mt-3 mb-3 pt-3"  style="height:130px;">
                            <p class="text--sm mb-0">{{translate('decking.summary.unload_x')}} {{$store.state.area.unload_x}} m</p>
                            <p class="text--sm mb-0">{{translate('decking.summary.unload_y')}} {{$store.state.area.unload_y}} m</p>
                            <p class="text--sm mb-0">{{translate('decking.summary.unload_h')}} {{$store.state.area.unload_h}} mm</p>
                        </div>
                    </div>

                    <div class="w-100 row" v-if="$store.state.pose_type.id == 1">
                        <div v-id="$store.state.magatello" class="col-lg-4 mb-lg-3 mb-5 col-12 text-center">
                            <p class="text--lg text-uppercase fw--bold fc--black">{{translate('steps.supporti.title')}}</p>
                            <img :src="$store.state.magatello.image" class="d-block mx-auto" style="height:160px;">
                            <p class="text--sm mb-1">{{$store.state.magatello.description}}</p>
                        </div>
                        <div class="col-lg-4 mb-lg-3 mb-5 col-12 text-center">
                            <p class="text--lg text-uppercase fw--bold fc--black">{{translate('steps.mattonella.title')}}</p>
                            <img :src="require('@/assets/images/svg/tile.svg')" class="d-block mx-auto mt-3 mb-3 pt-3"  style="height:130px;">
                            <p class="text--sm  mb-1">
                                <span v-for="tile in $store.state.tile">&nbsp;&nbsp;{{tile.name+': '+tile.value+'mm'}}&nbsp;&nbsp;</span>
                            </p>
                        </div>

                        <div class="col-lg-4 mb-5 mb-lg-3 col-12 text-center">
                            <p class="text--lg text-uppercase fw--bold fc--black">{{translate('decking.summary.area')}}</p>
                            <img :src="$store.state.form.image" class="d-block mx-auto" style="height:160px;">
                            <p class="text--sm mb-0">
                                <span v-for="area_size in $store.state.area.area_sizes">&nbsp;&nbsp;{{area_size.name+': '+area_size.value+'m'}}&nbsp;&nbsp;</span>
                            </p>
                            <p class="text--sm mb-1">{{translate('decking.fuga.title')}}: {{$store.state.leak}}mm</p>
                        </div>
                        <div v-if="$store.state.pose_direction != null" class="col-lg-4 mb-lg-3 mb-5 col-12 text-center mt-4">
                            <p class="text--lg text-uppercase fw--bold fc--black">{{translate('steps.pose.title')}}</p>
                            <img :src="$store.state.pose_direction.image" class="d-block mx-auto" style="height:160px;">
                            <p class="text--sm  mb-1">{{$store.state.pose_direction.description}}</p>
                        </div>
                        <div class="col-lg-4 mb-lg-3 mb-5 col-12 text-center mt-4">
                            <p class="text--lg text-uppercase fw--bold fc--black">{{translate('decking.summary.altezze')}}</p>
                            <img :src="require('@/assets/images/png/imgaltezze.png')" class="d-block mx-auto mt-3 mb-3 pt-3"  style="height:130px;">
                            <p class="text--sm mb-0">{{translate('decking.summary.height_a')}} {{$store.state.area.height_a}} mm</p>
                            <p class="text--sm mb-0">{{translate('decking.summary.height_b')}} {{$store.state.area.height_b}} mm</p>
                            <p class="text--sm mb-0">{{translate('decking.summary.height_c')}} {{$store.state.area.height_c}} mm</p>
                            <p class="text--sm mb-0">{{translate('decking.summary.height_d')}} {{$store.state.area.height_d}} mm</p>
                        </div>
                        <div class="col-lg-4 col-12 text-center mt-4">
                            <!-- <p class="text--lg text-uppercase fw--bold fc--black">{{translate('decking.summary.crea')}}</p>
                            <p class="text--sm">{{translate('decking.summary.creatext')}}</p> -->
                            <p class="text--lg text-uppercase fw--bold fc--black">{{translate('decking.summary.scarico')}}</p>
                            <img :src="require('@/assets/images/png/imgscarico.png')" class="d-block mx-auto mt-3 mb-3 pt-3"  style="height:130px;">
                            <p class="text--sm mb-0">{{translate('decking.summary.unload_x')}} {{$store.state.area.unload_x}} m</p>
                            <p class="text--sm mb-0">{{translate('decking.summary.unload_y')}} {{$store.state.area.unload_y}} m</p>
                            <p class="text--sm mb-0">{{translate('decking.summary.unload_h')}} {{$store.state.area.unload_h}} mm</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <transition name="fade">
            <div v-if="modalOpen" class="modal d-block">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text--lg fw--bold">{{translate('decking.summary.formtitle')}}</h5>
                            <button type="button" class="btn-close" @click="setModal(false)"></button>
                        </div>
                        <div class="modal-body">
                            <p class="text--md mb-3 mt-1">
                                {{translate('decking.summary.formtext')}}
                            </p>
                            <form class="form-template-1" method="post">
                                <div class="row">
                                    <div class="col-6 mb-3">
                                        <label class="text--sm">{{translate('decking.summary.formfirstname')}}*</label>
                                        <input class="w-100 text--md" placeholder="" required type="text" v-model="form.firstname">
                                    </div>
                                    <div class="col-6 mb-3">
                                        <label class="text--sm">{{translate('decking.summary.formlastname')}}*</label>
                                        <input class="w-100 text--md" placeholder="" required type="text" v-model="form.lastname">
                                    </div>
                                    <div class="col-12 mb-3">
                                        <label class="text--sm">{{translate('decking.summary.formemail')}}*</label>
                                        <input class="w-100 text--md" placeholder="" required type="email" v-model="form.email">
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button :disabled="$v.$invalid == true" class="btn btn-sm btn-primary btn-personalized-primary px-4" type="button" @click="register()">{{translate('decking.summary.formregister')}}</button>
                            <button type="button" class="ms-2 btn btn-sm btn-secondary px-4" @click="setModal(false)">{{translate('decking.summary.formabort')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </BaseLayout>
</template>

<script>
    import axios from 'axios';
    import {
        required,
        minLength
    } from 'vuelidate/lib/validators';
    export default {
        name: 'Summary',
        data: function() {
            return {
                modalOpen: false,
                form: {
                    firstname:null,
                    lastname:null,
                    email:null,
                }
            }
        },
        validations: {
            form: {
                firstname:required,
                lastname:required,
                email:required,
            }
        },
        methods: {
            setModal(state) {
                this.modalOpen = state;
            },
            prevStep(){
                return 'product-type';
            },
            nextStep(){
                return 'system';    // chiamare hubspot per andare al form
            },
            register(){
                this.$eventBus.$emit('SET-LOADER',true);
                let toSend = {};
                toSend.firstname = this.form.firstname;
                toSend.lastname = this.form.lastname;
                toSend.email = this.form.email;
                axios.post('/api/products/callHubSpot',toSend).then(response => {
                    this.$eventBus.$emit('SET-LOADER',false);
                    if (response.data.result == "OK") {
                        this.$store.commit('setContacts', this.form);
                        this.$router.push({
                            name: 'system',
                        })
                    } else {
                        this.$eventBus.$emit('ALERT-ERROR','Errore. Verifica i dati inseriti e riprova.');
                    }
                }).catch(e => {
                    this.$eventBus.$emit('SET-LOADER',false);
                    this.$eventBus.$emit('ALERT-ERROR','Errore. Verifica i dati inseriti e riprova.');
                });
            },
            titleposa() {
                if (this.$store.state.pose_type.id == 1) {
                    return 'Paving';
                } else {
                    return 'Decking';
                }
            }
        },
        mounted: function(){
            this.$eventBus.$on('HUBSPOT-REGISTER', (payload) => {
                this.setModal(true);
            });
        }
    }
</script>
