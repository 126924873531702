<template>
    <BaseLayout :titleposa="titleposa()" :title="$attrs.title" :subtitle="$attrs.subtitle" :steps="$attrs.steps" :variant="'default'"  :prev='prevStep()' :next="nextStep()">
        <div class="w-100 form-template-1 h-100 d-flex flex-column">
            <div class="row h-100 scroll">
                <div class="col-12 d-flex align-items-center">
                    <div class="w-100 row">
                        <div class="col-lg-12 order-1 order-lg-1">
                            <div class="row">
                                <div class="col-lg-12 order-1 order-lg-1 pt-1">
                                    <div class="row">
                                      <div class="col-lg-6 order-2 order-lg-1 pt-1">
                                        <div class="row">
                                          <div class="mb-3 col-6" v-for="size in area.area_sizes">
                                            <label class="text--lg mb-0 fw--bold text-uppercase" for="">{{size.name}}</label>
                                            <p class="text--sm  mb-2">{{translate('decking.metri.title')}}</p>
                                            <div class="d-flex w-100">
                                                <input @keypress="isNumber($event)" @click="setSelectedArea(size)" @focus="setSelectedArea(size)" type="number" :min="getMinSize(size)" class="text--md d-block c-input" style="width: 70%;" :class="[isAreaSelected(size) ? 'active' : '']" v-model="size.value" value="" placeholder="2">
                                                <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                                    <p class="text--sm fw--bold fc--blue px-1">m</p>
                                                </div>
                                            </div>
                                          </div>
                                          <p class="text--sm" id="latierror-error" style="display:none; color:red;">Attenzione. Verificare le dimensioni dell'area inserite</p>
                                        </div>

                                      </div>
                                      <div class="col-lg-3 order-1 order-lg-1 pt-1">
                                        <div class="w-100 d-flex align-items-start justify-content-center">
                                            <div class="tile-letters-box ratio ratio-1x1 position-relative h-auto">
                                                <img :src="require('@/assets/images/svg/area_'+$store.state.form.id+'.svg')" class="d-block w-100 h-100">
                                                <div v-for="size in area.area_sizes" :style="getLetterPosition(size)" class="tile-letters-box--letter text--md" :class="[isAreaSelected(size) ? 'active' : '']">
                                                    {{size.id}}
                                                </div>
                                            </div>
                                        </div>
                                      </div>

                                    </div>

                                </div>

                                
                                <div class="col-lg-6  mt-0 mb-0 pt-2 order-lg-3 order-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <label class="text--lg mb-0 fw--bold text-uppercase" for="">{{translate('decking.altezza.title')}}</label>
                                        </div>
                                        <div class="col-12">
                                          <p class="text--sm  mb-2">{{translate('decking.altezza.text')}}</p>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="row">
                                              <div class="col-lg-6 py-2">
                                                <label class="text--lg mb-0 fw--bold text-uppercase" for="">{{translate('decking.altezza.labelpoint')}} H1</label>
                                                <div class="d-flex w-100">
                                                  <input @click="setSelectedAreaHeight('a')" @focus="setSelectedAreaHeight('a')" @keypress="isNumber($event)" type="number" :min="getAreaPresetHeight()" class="text--md d-block c-input" style="width: 70%;" v-model="area.height_a" value="">
                                                  <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                                      <p class="text--sm fw--bold fc--blue px-1">mm</p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-lg-6 py-2">
                                                <label class="text--lg mb-0 fw--bold text-uppercase" for="">{{translate('decking.altezza.labelpoint')}} H2</label>
                                                <div class="d-flex">
                                                  <input @click="setSelectedAreaHeight('b')" @focus="setSelectedAreaHeight('b')" @keypress="isNumber($event)" type="number" :min="getAreaPresetHeight()" class="text--md d-block c-input" style="width: 70%;" v-model="area.height_b" value="">
                                                  <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                                      <p class="text--sm fw--bold fc--blue px-1">mm</p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-lg-6 py-2">
                                                <label class="text--lg mb-0 fw--bold text-uppercase" for="">{{translate('decking.altezza.labelpoint')}} H4</label>
                                                <div class="d-flex">
                                                  <input @click="setSelectedAreaHeight('d')" @focus="setSelectedAreaHeight('d')" @keypress="isNumber($event)" type="number" :min="getAreaPresetHeight()" class="text--md d-block c-input" style="width: 70%;" v-model="area.height_d" value="">
                                                  <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                                      <p class="text--sm fw--bold fc--blue px-1">mm</p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-lg-6 py-2">
                                                <label class="text--lg mb-0 fw--bold text-uppercase" for="">{{translate('decking.altezza.labelpoint')}} H3</label>
                                                <div class="d-flex">
                                                  <input @click="setSelectedAreaHeight('c')" @focus="setSelectedAreaHeight('c')" @keypress="isNumber($event)" type="number" :min="getAreaPresetHeight()" class="text--md d-block c-input" style="width: 70%;" v-model="area.height_c" value="">
                                                  <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                                      <p class="text--sm fw--bold fc--blue px-1">mm</p>
                                                  </div>
                                                </div>
                                              </div>

                                              <p class="text--sm" id="pendenza-error" style="display:none; color:red;">La pendenza della pavimentazione è superiore al 5%, modificare le altezze</p>
                                              <p class="text--sm" id="poliedric-error" style="display:none; color:red;">Superficie con altezze non compatibili</p>
                                              <div class="col-lg-12">
                                                <img :src="require('@/assets/images/png/altezza-pavimentazione-terrazzo.jpg')" class="d-block" style="height:70px;"></img>
                                              </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <!-- <img :src="''+$store.state.magatello.image_h_svg+''" class="d-block w-auto h-100 pt-0" style="margin-top: -60px;"> -->
                                            <div class="w-100 d-flex align-items-start justify-content-center">
                                                <div class="tile-letters-box ratio ratio-1x1 position-relative h-auto">
                                                    <img :src="require('@/assets/images/svg/heightarea_'+$store.state.form.id+'.svg')" class="d-block w-100 h-100">
                                                    <div v-for="size in area.area_heights" :style="getLetterHeightPosition(size)" class="tile-letters-box--letter text--md" :class="[isAreaHeightSelected(size.id) ? 'active' : '']">
                                                        {{size.id}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6  mt-0 mb-0 pt-2 order-lg-4 order-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <label class="text--lg mb-0 fw--bold text-uppercase" for="">{{translate('decking.scarico.title')}}</label>
                                        </div>
                                        <div class="col-12">
                                          <p class="text--sm  mb-2">{{translate('decking.scarico.text')}}</p>
                                        </div>
                                        <div class="col-12">
                                            <input type="checkbox" name="ispuntoscarico" value="1" v-model="area.isPuntoScarico"> <span class="text--sm">{{translate('decking.scarico.presente')}}</span>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="row">

                                                    <div class="col-lg-6 py-2">
                                                        <label class="text--lg mb-0 fw--bold text-uppercase" for="">{{translate('decking.scarico.distance')}} X</label>
                                                        <div class="d-flex w-100">
                                                        <input id="unload_x" @click="setSelectedAreaUnload('x')" @focus="setSelectedAreaUnload('x')" :disabled="!area.isPuntoScarico" @keypress="isNumber($event)" type="number" :min="getAreaPresetUnload('x')" class="text--md d-block c-input" style="width: 70%;" v-model="area.unload_x" value="">
                                                        <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                                            <p class="text--sm fw--bold fc--blue px-1">m</p>
                                                        </div>
                                                        </div>
                                                        <p class="text--sm" id="unload_x-error" style="display:none; color:red;">Deve essere nella superficie e maggiore di 5mm</p>
                                                    </div>
                                                    <div class="col-lg-6 py-2">
                                                        <label class="text--lg mb-0 fw--bold text-uppercase" for="">{{translate('decking.scarico.distance')}} Y</label>
                                                        <div class="d-flex w-100">
                                                        <input id="unload_y" @click="setSelectedAreaUnload('y')" @focus="setSelectedAreaUnload('y')" :disabled="!area.isPuntoScarico" @keypress="isNumber($event)" type="number" :min="getAreaPresetUnload('y')" class="text--md d-block c-input" style="width: 70%;" v-model="area.unload_y" value="">
                                                        <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                                            <p class="text--sm fw--bold fc--blue px-1">m</p>
                                                        </div>
                                                        </div>
                                                        <p class="text--sm" id="unload_y-error" style="display:none; color:red;">Deve essere nella superficie e maggiore di 5mm</p>
                                                    </div>
                                                    </div>
                                                    <!-- <p class="text--sm  mb-2">{{translate('decking.scarico.height')}}</p> -->
                                                    <div class="row">
                                                    <div class="col-lg-12 py-2">
                                                        <label class="text--lg mb-0 fw--bold text-uppercase" for="">{{translate('decking.scarico.height')}}</label>
                                                        <div class="d-flex w-100">
                                                        <input id="unload_h" :disabled="!area.isPuntoScarico" @click="setSelectedAreaUnload('h')" @keypress="isNumber($event)" type="number" :min="getAreaPresetUnloadHeight()" class="text--md d-block c-input" style="width: 70%;" v-model="area.unload_h" value="">
                                                        <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                                            <p class="text--sm fw--bold fc--blue px-1">mm</p>
                                                        </div>
                                                        </div>
                                                        <p class="text--sm" id="unload_h-error" style="display:none; color:red;">L'altezza dello scarico deve essere superiore all'altezza della pavimentazione</p>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="w-100 d-flex align-items-start justify-content-center">
                                                        <div class="tile-letters-box ratio ratio-1x1 position-relative h-auto">
                                                            <img :src="require('@/assets/images/png/scarico.png')" class="d-block w-100 h-100">
                                                            <div v-for="size in area.unloads" :style="getLetterUnloadPosition(size)" class="tile-letters-box--letter-small text--md" :class="[isAreaUnloadSelected(size.id) ? 'active' : '']">
                                                                {{size.id}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>


<script>
    import axios from 'axios';
    // import {
    //     required,
    //     minLength
    // } from 'vuelidate/lib/validators';
    export default {
        name: 'AreaSize',
        data: function() {
            return {
                selected_area:null,
                selected_area_height:null,
                selected_area_unload:null,
                area:{
                    area_sizes: [
                        // {id:'a', name:'Lato A', value:0},
                        // {id:'b', name:'Lato B', value:0},
                    ],
                    // leak: (this.$store.state.area && this.$store.state.leak) ? this.$store.state.leak : this.$store.state.fughetenant[0],
                    height_a: (this.$store.state.area && this.$store.state.area.height_a) ? this.$store.state.area.height_a : this.getAreaPresetHeight('A'),
                    height_b: (this.$store.state.area && this.$store.state.area.height_b) ? this.$store.state.area.height_b : this.getAreaPresetHeight('B'),
                    height_c: (this.$store.state.area && this.$store.state.area.height_c) ? this.$store.state.area.height_c : this.getAreaPresetHeight('C'),
                    height_d: (this.$store.state.area && this.$store.state.area.height_d) ? this.$store.state.area.height_d : this.getAreaPresetHeight('D'),
                    unload_x: (this.$store.state.area && this.$store.state.area.unload_x) ? this.$store.state.area.unload_x : this.getAreaPresetUnload('x'),
                    unload_y: (this.$store.state.area && this.$store.state.area.unload_y) ? this.$store.state.area.unload_y : this.getAreaPresetUnload('y'),
                    unload_h: (this.$store.state.area && this.$store.state.area.unload_h) ? this.$store.state.area.unload_h : this.getAreaPresetUnloadHeight(),
                    isPuntoScarico: (this.$store.state.area && this.$store.state.area.isPuntoScarico) ? this.$store.state.area.isPuntoScarico : false,
                    isSconnesso: (this.$store.state.area && this.$store.state.area.isSconnesso) ? this.$store.state.area.isSconnesso : false,
                }
            }
        },
        methods: {
            isCheckedPuntoScarico(){
                this.area.isPuntoScarico;
                console.log("valore check "+this.area.isPuntoScarico);
            },
            getMinSize(size){
                // se forma L
                if(this.$store.state.form.id == 1){
                    if(size.id == 'a'){
                        return Number(Number(this.$store.state.area.area_sizes[3].value)+1);
                    } else if (size.id == 'c'){
                        return Number(Number(this.$store.state.area.area_sizes[1].value)+1);
                    } else {
                        return 1;
                    }
                } else {
                    return 1;
                }

            },
            checkHeights(){
                // 1 verifico che il punto di scarico non sia
                let continua = true;
                console.log("calcolo pendenza");
                // console.log("h: "+this.$store.state.area.unload_h,this.$store.state.area.height_a,this.$store.state.area.height_b,this.$store.state.area.height_c,this.$store.state.area.height_d);
                let arrayaltezze = [this.$store.state.area.height_a,this.$store.state.area.height_b,this.$store.state.area.height_c,this.$store.state.area.height_d];
                let min = arrayaltezze.reduce((a, b) => Math.min(a, b));
                let max = arrayaltezze.reduce((a, b) => Math.max(a, b));
                // console.log("h: "+this.$store.state.area.unload_h,min,max);
                // console.dir(this.$store.state.area);
                if(this.$store.state.area.isPuntoScarico) {
                    if(this.$store.state.area.unload_h > max) {
                        // console.log("scarico ok");
                        this.$el.querySelector("#unload_h").style.color = 'black';
                        this.$el.querySelector("#unload_h").style.border = '1px solid black';
                        this.$el.querySelector("#unload_h-error").style.display = 'none';
                    } else {
                        // console.log("scarico non ok");
                        continua = false;
                        this.$el.querySelector("#unload_h").style.color = 'red';
                        this.$el.querySelector("#unload_h").style.border = '1px solid red';
                        this.$el.querySelector("#unload_h-error").style.display = 'block';
                    }
                } else {
                    this.$store.state.area.unload_h = min;
                    continua = true;
                }

                let difference = max - min;
                let pendenza = 0;
                if(this.$store.state.area.area_sizes[0].value > this.$store.state.area.area_sizes[1].value) {
                    let ipotenusa = Math.sqrt(Math.pow(difference,2) + Math.pow(this.$store.state.area.area_sizes[0].value*1000,2));
                    pendenza = parseFloat(Math.atan(parseFloat(difference)/parseFloat(ipotenusa)));
                } else {
                    let ipotenusa = Math.sqrt(Math.pow(difference,2) + Math.pow(this.$store.state.area.area_sizes[1].value*1000,2));
                    pendenza = parseFloat(Math.atan(parseFloat(difference)/parseFloat(ipotenusa)));
                }
                if(pendenza > 0.05) {
                    continua = false;
                    this.$el.querySelector("#pendenza-error").style.display = 'block';
                }

                // controllo anche posizione x e y dello scarico se dentro superficie
                if(this.$store.state.area.area_sizes[0].value < this.$store.state.area.unload_x && this.$store.state.area.unload_x > 0.1 && this.$store.state.area.isPuntoScarico) {
                    this.$el.querySelector("#unload_x").style.color = 'red';
                    this.$el.querySelector("#unload_x").style.border = '1px solid red';
                    this.$el.querySelector("#unload_x-error").style.display = 'block';
                    continua = false;
                }
                if(this.$store.state.area.area_sizes[1].value < this.$store.state.area.unload_y && this.$store.state.area.unload_y > 0.1 && this.$store.state.area.isPuntoScarico) {
                    this.$el.querySelector("#unload_y").style.color = 'red';
                    this.$el.querySelector("#unload_y").style.border = '1px solid red';
                    this.$el.querySelector("#unload_y-error").style.display = 'block';
                    continua = false;
                }

                // verifica poliedrico
                if( (arrayaltezze[0] == arrayaltezze[1] && arrayaltezze[2] == arrayaltezze[3]) ||
                    (arrayaltezze[0] == arrayaltezze[3] && arrayaltezze[1] == arrayaltezze[2])) {
                } else {
                    this.$el.querySelector("#poliedric-error").style.display = 'block';
                    continua = false;
                }

                // verifica lati, non serve per fare tutte le L
                
                // latierror-error

                if(continua) {
                    this.$router.push({
                        name: 'product-type',
                    });
                }

            },
            getAreaPresetHeight(point){
                let num = Number((Number(this.$store.state.tile[2].value) + 10));

                if (this.$store.state.magatello.id == 1){
                     num = Number((num + 30));
                }
                // se ha il magatello 18, se senza 10

                return num;
            },
            getAreaPresetUnload(point){
              let num = 1;
              if(point == "x") {
                let num = Number(this.$store.state.unload_x);
              } else {
                let num = Number(this.$store.state.unload_y);
              }
              return num;
            },
            getAreaPresetUnloadHeight(){
              let num = Number((Number(this.$store.state.tile[2].value) + 5));
              if (this.$store.state.magatello.id == 1){
                    num = Number((num + 30));
              }
              return num;
            },
            getLetterPosition(size){
                if (this.$store.state.form.id == 0) {
                    //forma rettangolare
                    switch (size.id) {
                        case 'a':
                        return 'top:26%; left:45%;';
                            break;
                        case 'b':
                        return 'top:42%; left:91%;';
                            break;
                    }
                } else if(this.$store.state.form.id == 1){
                    //forma a L
                    switch (size.id) {
                        case 'a':
                        return 'top:21%; left:56%;';
                            break;
                        case 'b':
                        return 'top:37%; left:97%;';
                            break;
                        case 'c':
                        return 'top:43%; left:3%;';
                            break;
                        case 'd':
                        return 'top:69%; left:9%;';
                            break;
                    }
                }
            },
            getLetterUnloadPosition(size){
                switch (size.id) {
                    case 'x':
                    return 'top:80%; left:49%;';
                        break;
                    case 'y':
                    return 'top:75%; left:9%;';
                        break;

                }
            },
            getLetterHeightPosition(size){
                if (this.$store.state.form.id == 0) {
                    //forma rettangolare
                    switch (size.id) {
                        case 'H1':
                        return 'top:29%; left:15%;';
                            break;
                        case 'H2':
                        return 'top:29%; left:75%;';
                            break;
                        case 'H3':
                        return 'top:61%; left:96%;';
                            break;
                        case 'H4':
                        return 'top:61%; left:-5%;';
                            break;

                    }
                } else if(this.$store.state.form.id == 1){
                    //forma a L
                    switch (size.id) {
                        case 'H1':
                        return 'top:29%; left:15%;';
                            break;
                        case 'H2':
                        return 'top:29%; left:75%;';
                            break;
                        case 'H3':
                        return 'top:61%; left:96%;';
                            break;
                        case 'H4':
                        return 'top:61%; left:-5%;';
                            break;
                    }
                }
            },
            setSelectedArea(size){
                this.$store.commit('setArea', this.area);
                this.selected_area = size;
            },
            setSelectedAreaHeight(size){
                this.$store.commit('setArea', this.area);
                this.selected_area_height = size;
            },
            setSelectedAreaUnload(size){
                this.$store.commit('setArea', this.area);
                this.selected_area_unload = size;
            },
            isAreaSelected(size){
                if (this.selected_area && this.selected_area.id) {
                    if (size.id == this.selected_area.id) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return  false;
                }
            },
            isAreaUnloadSelected(size){
                if (this.selected_area_unload) {
                    if (size == this.selected_area_unload) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return  false;
                }
            },
            isAreaHeightSelected(size){
                if (this.selected_area_height) {
                    if (size == this.selected_area_height) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return  false;
                }
            },
            prevStep(){
                return 'area-form';
            },
            nextStep(){
                return 'product-type';
            },
            isMoreThanMin(){
                // setTimeout(function(){
                //     if(evt.target.value < evt.target.min)
                //     console.log('valore < min');
                //         evt.target.value = evt.target.min;
                // }, 1500);
            },
            isNumber: function(evt) {

                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode != 44 && charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();
                    // getMinSize(size);
                } else {

                // setTimeout(function(){
                //     if(evt.target.value < evt.target.min)
                //     console.log('valore < min');
                //         evt.target.value = evt.target.min;
                // }, 1500);

                return true;
                }
            },
            titleposa() {
                if (this.$store.state.pose_type.id == 1) {
                    return 'Paving';
                } else {
                    return 'Decking';
                }
            }
        },
        mounted: function() {
            let area_sizes_rect = [
                {id:'a', name:this.translate('decking.lato.a'), value:(this.$store.area && this.$store.state.area.area_sizes) ? this.$store.state.area.area_sizes[0].value : 10},
                {id:'b', name:this.translate('decking.lato.b'), value:(this.$store.area && this.$store.state.area.area_sizes) ? this.$store.state.area.area_sizes[1].value : 5},
            ];
            let area_sizes_l = [
                {id:'a', name:this.translate('decking.lato.a'), value:(this.$store.area && this.$store.state.area.area_sizes) ? this.$store.state.area.area_sizes[0].value : 10},
                {id:'b', name:this.translate('decking.lato.b'), value:(this.$store.area && this.$store.state.area.area_sizes) ? this.$store.state.area.area_sizes[1].value : 3},
                {id:'c', name:this.translate('decking.lato.c'), value:(this.$store.area && this.$store.state.area.area_sizes) ? this.$store.state.area.area_sizes[2].value : 5},
                {id:'d', name:this.translate('decking.lato.d'), value:(this.$store.area && this.$store.state.area.area_sizes) ? this.$store.state.area.area_sizes[3].value : 4},
            ];
            let area_heights_rect = [
                {id:'H1'},
                {id:'H2'},
                {id:'H3'},
                {id:'H4'}
            ];
            let unloads = [
                {id:'x'},
                {id:'y'}
            ];
            let area_heights_l = [
                {id:'H1'},
                {id:'H2'},
                {id:'H3'},
                {id:'H4'}
            ];

            switch (this.$store.state.form.id) {
                case 0:
                    //FORMA RETTANGOLARE
                    this.area.area_sizes = area_sizes_rect;
                    this.area.area_heights = area_heights_rect;
                    this.area.unloads = unloads;
                    break;
                case 1:
                    //FORMA A L
                    this.area.area_sizes = area_sizes_l;
                    this.area.area_heights = area_heights_l;
                    this.area.unloads = unloads;
                    break;
            }
            if (!this.$store.state.area) {
                this.$store.commit('setArea', this.area);
            }

            this.$eventBus.$on('HEIGHTS-CHECK', (payload) => {
                this.checkHeights();
            });
        },
        watch: {
            'area': {
                handler: function(oldVal, newVal) {
                    this.$store.commit('setArea', newVal);
                },
                deep: true
            }
        }
    }
</script>
