<template>
    <div class="home-screen w-100 vh-100 d-flex flex-column">
        <div class="container home-screen--textbox">
            <div class="row w-100">
                <div class="col-lg-10">
                    <p class="fc--black fw--bold text--xxl mb-lg-0 mb-2 text-lg-start text-center">{{resolveFromConfig('builder_name', '')[currentLocaleLang]}}</p>
                    <p class="fc--black fw--bold text--xxl mb-4 text-lg-start text-center">{{resolveFromConfig('builder_slogan', '')[currentLocaleLang]}}</p>
                    <p class="text--md fc--black fw--light mb-4 text-lg-start text-center" v-html="resolveFromConfig('builder_abstract', '')[currentLocaleLang]"></p>
                    <div class="row">
                        <div class="col-lg-4">
                            <label class="text--md mb-0 fw--bold text-uppercase text-lg-start text-center" for="">{{translate('decking.login.login')}}</label>
                            <input type="text" class="text--md d-block c-input" style="width: 70%;" v-model="login.login" value="">
                        </div>
                        <div class="col-lg-4">
                            <label class="text--md mb-0 fw--bold text-uppercase text-lg-start text-center" for="">{{translate('decking.login.password')}}</label>
                            <input type="password" class="text--md d-block c-input" style="width: 70%;" v-model="login.password" value="">
                        </div>
                        <div class="col-lg-3">
                            <!-- <button @click="nextStep()" class="btn text--sm text-uppercase btn-outline-primary btn-personalized-outline mt-3" type="button">{{translate('decking.login.btn')}}</button> -->
                            <button @click="loginAction" class="btn text--sm text-uppercase btn-outline-primary btn-personalized-outline mt-3 btn-entra" type="button">{{translate('decking.login.btn')}}</button>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <p class="text--md text-lg-start text-center">{{translate('decking.login.registermessage')}} <a href="mailto:mc@dakota.eu?Subject=Richiesta registrazione configuratore Raisedfloor">{{translate('decking.login.support')}}</a></p>
                        </div>
                    </div>
                    <!-- <button @click="nextStep()" class="btn text--md text-uppercase btn-outline-primary btn-personalized-outline" type="button">{{translate('decking.intro.cta')}}</button> -->
                </div>
                <div class="col-lg-2 col-12 text-lg-start text-center mt-lg-0 mt-3">
                    <p class="text--sm mb-2 text-uppercase fw--bold">Select language</p>
                    <p class="fc--black fw--bold text--xxl" v-if="builderLang.length >= 2">
                        <div class="d-flex">
                            <div v-for="lang in builderLang" class="text-uppercase text-center blocklangcontainer">
                                <a @click="switchLang(lang)" class="blocklang fw--bold">
                                    <!-- <country-flag :country="lang == 'en' ? 'gb' : lang" size='small'/> -->
                                    {{lang}}
                                </a>
                            </div>
                        </div>
                    </p>
                </div>
            </div>
        </div>
        <div class="home-screen--imgbox container-fluid" :style='{backgroundImage:"url("+ resolveFromConfig("featured", require("@/assets/images/home-bg.jpg")) +")"}'></div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: 'HomeScreen',
        data: function() {
            return {
                login:{
                    login:"",
                    password:"",
                    errormessage: this.translate('decking.login.errormessage'),
                    emailsubject: this.translate('decking.login.emailsubject')
                }
            }
        },
        methods: {
            loginAction(){
                this.$eventBus.$emit('SET-LOADER',true);
                let toSend = {};
                toSend.login=this.login.login;
                toSend.password=this.login.password;
                axios.post('/api/login/front', toSend).then(response => {
                    this.esito = response.data.esito;
                    if(this.esito == "ok") {
                        this.$router.push({
                            name: 'select-pose',
                        });
                    } else {
                        alert(this.login.errormessage);
                    }
                }).catch(e => {
                    this.$eventBus.$emit('SET-LOADER',false);
                    // this.$eventBus.$emit('ALERT-ERROR','Errore di rete.');
                });
            },
            nextStep(){
                this.$router.push({
                    name: 'select-pose',
                })
            }
        },
        beforeMount: function() {
            if(this.$route.query.key) {
                // verifico il parametro
                this.$eventBus.$emit('SET-LOADER',true);
                let toSend = {};
                toSend.key=this.$route.query.key;
                axios.post('/api/login/keyexist', toSend).then(response => {
                    this.esito = response.data.esito;
                    //localStorage.setItem('build-spa-lang', lang);
                    this.$store.commit('build-spa-lang','it');
                    if(this.esito == "ok") {

                        this.$store.commit('setUser', this.$route.query.key);
                        this.$router.push({
                            name: 'select-pose',
                        });
                    } else {

                        this.$eventBus.$emit('SET-LOADER',false);
                    }
                }).catch(e => {
                    this.$eventBus.$emit('SET-LOADER',false);
                    // this.$eventBus.$emit('ALERT-ERROR','Errore di rete.');
                });
            }

        }
    }
</script>
