<template>
    <BaseLayout :titleposa="titleposa()" :title="$attrs.title" :subtitle="$attrs.subtitle" :steps="$attrs.steps" :variant="'default'"  :prev='prevStep()' :next="nextStep()">
        <div class="w-100 form-template-1 h-100 d-flex flex-column">
            <div class="row h-100 scroll">
                <div class="col-12 d-flex align-items-center">
                    <div class="w-100 row">
                        <div @click="setTileOrientation(mt)" v-for="mt in tile_orientations" class="d-block col-lg-6 mb-5 mb-lg-0 h-auto text-center">
                            <div class="px-5 mb-5">
                                <div class="">
                                    <img v-if="!$store.state.tile_orientation || mt.id != $store.state.tile_orientation.id" :src="mt.image" class="d-block w-75 sm:w-100 mx-auto h-auto px-lg-5">
                                    <img v-if="$store.state.tile_orientation && mt.id == $store.state.tile_orientation.id" :src="mt.image_active" class="d-block w-75 sm:w-100 mx-auto h-auto px-lg-5">
                                </div>
                                <p class="text--xl fw--bold text-uppercase fc--black mt-3 mb-1">{{translate(mt.description)}}</p>
                                <p class="text--md fc--black">{{translate(mt.subtitle)}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
    import axios from 'axios';
    export default {
        name: 'TilesOrientation',
        data: function() {
            return {
                tile_orientations: [
                    {
                        id: 0,
                        title: 'O.',
                        description: this.translate('decking.orizzontal.title'),
                        subtitle: this.translate('decking.orizzontal.text'),
                        image: require('@/assets/images/png/orizz_off.png'),
                        image_active: require('@/assets/images/png/orizz_on.png'),
                    },
                    {
                        id: 1,
                        title: 'V.',
                        description: this.translate('decking.vertical.title'),
                        subtitle: this.translate('decking.vertical.text'),
                        image: require('@/assets/images/png/vert_off.png'),
                        image_active: require('@/assets/images/png/vert_on.png'),
                    }
                ],
            }
        },
        mounted: function() {
            if (!this.$store.state.tile_orientation) {
                this.setTileOrientation(this.tile_orientations[0]);
            }
        },
        methods: {
            setTileOrientation(orientation){
                this.$store.commit('setTileOrientation', orientation);
            },
            prevStep(){
                return 'area-size';
            },
            nextStep(){
                //Se paving con magatello,  no posa pavimento
                if (this.$store.state.pose_type.id == 1 && this.$store.state.magatello.id == 1 ) {
                    return 'product-type';
                } else {
                    return 'pose-direction';
                }
            },
            titleposa() {
                if (this.$store.state.pose_type.id == 1) {
                    return 'Paving';
                } else {
                    return 'Decking';
                }
            }
        }
    }
</script>
