<template>
    <div class="page" :class="variant">
        <!-- SIDEBAR -->
        <div class="header-mobile px-3">
            <img @click="homeRedirect" :src="resolveFromConfig('logo', require('@/assets/images/logo.png'))" class="d-block w-50 py-2 px-3 mx-auto h-auto  cursor-pointer">
        </div>
        <div class="page-header">
            <div class="row w-100">
                <div class="col-12 text-left">
                    <p class="text--md fw--bold text-uppercase pt-3 text-lg-start text-center">{{titleposa}}</p>
                </div>
                <div class="col-12 pt-lg-5 pt-4">
                    <p v-if="title" class="text--xl text-uppercase d-block fc--black fw--bold text-center mt-2">
                        {{title}}
                    </p>
                    <p v-if="subtitle" class="text--sm d-block fc--black text-center" v-html="subtitle">
                    </p>
                </div>
            </div>
        </div>
        <div class="page-container row">
            <!-- contenuto pagina -->
            <slot></slot>
        </div>
        <div class="page-footer">
            <div class="row w-100 px-3 g-0">
                <div class="col-12 d-flex justify-content-lg-end justify-content-between">
                    <button @click="goToPrev()" :disabled="!prev" class="btn text--md text-uppercase px-4 btn-outline-primary btn-personalized-outline" type="button">{{prevText}}</button>
                    <button v-if="next != 'quotation'" @click="goToNext()" :disabled="!next" class="btn text--md text-uppercase px-4 btn-primary btn-personalized-primary ms-2" type="button">{{nextText}}</button>
                </div>
            </div>
        </div>

        <div class="menu-mobile">
            <div class="row w-100 p-2 g-0">
                <div class="col-12 d-flex justify-content-lg-end justify-content-between">
                    <button @click="goToPrev()" :disabled="!prev" class="btn text--md text-uppercase px-4 btn-outline-primary btn-personalized-outline" type="button">
                        <svg v-if="$route.name != 'system'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.69 59.72"><path d="M1.21,32l27.7,27.69,4.18-4.18L10.44,32.9H58.69V27H10.44L33.09,4.34,28.91.16,1.21,27.85A3,3,0,0,0,1.21,32Z"/></svg>
                        <svg v-if="$route.name == 'system'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.44 30.85"><path d="M25.27,6.54a4,4,0,0,1,.83,1.31,4,4,0,0,1,.34,1.51V29.2a1.64,1.64,0,0,1-1.64,1.65H1.65A1.64,1.64,0,0,1,0,29.21H0V1.65A1.64,1.64,0,0,1,1.64,0H17.08a4,4,0,0,1,1.51.34,4,4,0,0,1,1.31.83Zm-7.64-4.2V8.81H24.1a1.78,1.78,0,0,0-.38-.7L18.33,2.72a2,2,0,0,0-.71-.38Zm6.61,26.3V11H17.08a1.66,1.66,0,0,1-1.66-1.64V2.2H2.2V28.65h22Zm-8.85-10.2a13,13,0,0,0,1.45,1,16.81,16.81,0,0,1,2-.12c1.69,0,2.7.28,3,.84a.84.84,0,0,1,0,.9.05.05,0,0,1,0,0l0,0v0c-.07.43-.47.65-1.22.65a7,7,0,0,1-2-.34,12.52,12.52,0,0,1-2.24-.91,30.51,30.51,0,0,0-6.75,1.43c-1.75,3-3.14,4.51-4.16,4.51A1,1,0,0,1,5,26.32l-.41-.21L4.53,26a.69.69,0,0,1-.1-.62,3.79,3.79,0,0,1,1-1.58,8.62,8.62,0,0,1,2.27-1.66.25.25,0,0,1,.36,0,.25.25,0,0,1,0,.07.09.09,0,0,1,0,.07c.6-1,1.21-2.1,1.84-3.39a25.83,25.83,0,0,0,1.79-4.51,13.36,13.36,0,0,1-.52-2.75,6.45,6.45,0,0,1,.11-2.19c.12-.46.37-.69.72-.69h.38a.73.73,0,0,1,.6.26,1.39,1.39,0,0,1,.16,1.17.61.61,0,0,1-.07.14.61.61,0,0,1,0,.14V11a22.93,22.93,0,0,1-.24,3.31,8.84,8.84,0,0,0,2.51,4.1ZM5.47,25.51A7.61,7.61,0,0,0,7.83,22.8a9.73,9.73,0,0,0-1.5,1.44A5.79,5.79,0,0,0,5.47,25.51ZM12.33,9.67a5.32,5.32,0,0,0,0,2.28c0-.08.06-.34.13-.76,0,0,0-.28.12-.74a.27.27,0,0,1,.07-.14l0,0a0,0,0,0,0,0,0v0a1,1,0,0,0-.22-.62l0,0v0ZM10.19,21.05a26,26,0,0,1,4.89-1.39l-.22-.16a3.22,3.22,0,0,1-.28-.24,9,9,0,0,1-2.18-3A24.07,24.07,0,0,1,11,19.62Zm11.12-.27a4.09,4.09,0,0,0-2.41-.41,6.53,6.53,0,0,0,2.14.48,1.67,1.67,0,0,0,.31,0s0,0,0,0Z"/></svg>
                    </button>
                    <div class="d-flex align-items-center w-100 px-3">
                        <div id="js--hamburger" class="hamburger">

                        </div>
                        <p v-if="title" class="ps-3 text--xxs text-uppercase d-block fc--black fw--bold">
                            {{title}}
                        </p>
                    </div>

                    <button @click="goToNext()" :disabled="!next" class="btn text--md text-uppercase px-4 btn-outline-primary btn-personalized-outline ms-2" type="button">
                        <svg v-if="$route.name != 'system'"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.69 59.72"><path d="M57.82,27.85,30.13.16,26,4.34,48.59,27H.35V32.9H48.59L26,55.54l4.18,4.18L57.82,32A3,3,0,0,0,57.82,27.85Z"/></svg>
                        <svg v-if="$route.name == 'system'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.38 30.85"><path d="M0,12.63H6.38V30.85H0Z" transform="translate(0 0)"/><path d="M0,0H6.38V6.38H0Z" transform="translate(0 0)"/></svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
      translate
    } from '@/VueTranslation/Translation';

    import axios from 'axios';
    import Lottie from 'lottie-web';
    import gsap from "gsap";

    export default {
        name: 'BaseLayout',
        props: {
            prev: null,
            next: null,
            title: String,
            titleposa: String,
            subtitle: String,
            variant: {
                type: String,
                default: 'default'
            },
        },
        data: function(){
            return {
                nextText:'Continua',
                prevText:'Precedente'
            }
        },
        mounted: function(){
            if (this.prev == 'pdf') {
                this.prevText = translate('decking.base.pdf');
            } else {
                this.prevText = translate('decking.base.precedente');
            }

            if (this.next == 'hubspot') {
                this.nextText = translate('decking.base.calculate');
            } else if (this.next == 'quotation') {
                this.nextText = translate('decking.base.requestpreventivo');
            } else {
                this.nextText = translate('decking.base.successivo');
            }

            let hamburger = document.querySelector('#js--hamburger');
            let menuMobile = document.querySelector('#js--menu-mobile');

             hamburger.mobileMenuTl = gsap.timeline({
              paused: true,
              reversed: true
            })
            .to(menuMobile, .001, {display:'block',immediateRender:false})
            .fromTo(menuMobile, .5, {autoAlpha:0}, {autoAlpha:1,immediateRender:false});

            hamburger.JSON_hambuger_animation = Lottie.loadAnimation({
              container: hamburger, // Required
              animationData: require('@/assets/images/hamburger.json'),
              renderer: 'svg', // Required
              loop: false, // Optional
              autoplay: false, // Optional
              filterSize: {
                  width: '100%',
                  height: '100%'
                }
            });
            hamburger.JSON_hambuger_animation.setSpeed(2);

            hamburger.addEventListener('click', function () {
              if (hamburger.mobileMenuTl.reversed()) {
                hamburger.JSON_hambuger_animation.setDirection(1);
                hamburger.JSON_hambuger_animation.play();
                setTimeout(() => {
                  hamburger.mobileMenuTl.play();
                }, 500);
              } else {
                hamburger.mobileMenuTl.reverse().eventCallback('onReverseComplete', function () {
                  hamburger.JSON_hambuger_animation.setDirection(-1);
                  hamburger.JSON_hambuger_animation.play();
                });
              }
            })

            this.$eventBus.$on('HIDE-MOBILE-MENU', () => {
                  hamburger.mobileMenuTl.reverse().eventCallback('onReverseComplete', function () {
                    hamburger.JSON_hambuger_animation.setDirection(-1);
                    hamburger.JSON_hambuger_animation.play();
                  });
            });

        },
        methods: {
            homeRedirect(){
                window.location.href = "/"
            },
            goToPrev(){
                if (this.prev == 'pdf') {
                    let toSend = {};
                    toSend.area = this.$store.state.area;
                    toSend.magatello = this.$store.state.magatello;
                    toSend.tile = this.$store.state.tile;
                    toSend.pose_direction = this.$store.state.pose_direction;
                    toSend.tile_orientation = this.$store.state.tile_orientation;
                    toSend.pose_type = this.$store.state.pose_type;
                    toSend.product = this.$store.state.system;
                    toSend.contacts = this.$store.state.contacts;
                    toSend.calcs=this.$store.state.calcs;
                    toSend.prolunghe=this.$store.state.extensions;
                    toSend.accessori=this.$store.state.accessori;
                    toSend.matrice = this.$store.state.matrice;
                    toSend.livingproduct = this.$store.state.livingproduct;
                    toSend.infomagatello = this.$store.state.infomagatello;
                    toSend.leak = this.$store.state.leak;
                    toSend.keyutente = this.$store.state.keyutente;
                    toSend.proposalid = this.$store.state.proposalid;
                    if(this.$store.state.magatello.id > 1) {
                        toSend.disposizionemateriale = this.$store.state.pose_direction.id;
                    }

                    //Transform object with map attribute to current locale
                    //#@todo il prodotto è sempre oggetto oppure può essere passato come array?


                    this.$eventBus.$emit('SET-LOADER-PDF',true);

                    axios.post('/api/pdf/create', toSend).then(response => {
                        this.$eventBus.$emit('SET-LOADER-PDF',false);
                        window.location.href =response.data.link;
                    }).catch(e => {
                        this.$eventBus.$emit('SET-LOADER-PDF',false);
                        this.$eventBus.$emit('ALERT-ERROR','Errore di rete.');
                    });
                } else {
                    this.$router.push({
                        name: this.prev,
                    })
                }
            },
            goToNext(){
                if(this.next == 'product-type') {
                    // verifico i dati
                    this.$eventBus.$emit('HEIGHTS-CHECK',false);
                } else if (this.next == 'hubspot') {
                    this.$eventBus.$emit('HUBSPOT-REGISTER',false);
                } else if (this.next == 'quotation') {
                    // TODO: api preventivo
                    let toSend = {};
                    toSend.area = this.$store.state.area;
                    toSend.magatello = this.$store.state.magatello;
                    toSend.tile = this.$store.state.tile;
                    toSend.pose_direction = this.$store.state.pose_direction;
                    toSend.tile_orientation = this.$store.state.tile_orientation;
                    toSend.pose_type = this.$store.state.pose_type;
                    toSend.product = this.$store.state.system;
                    toSend.contacts = this.$store.state.contacts;
                    toSend.calcs=this.$store.state.calcs;
                    this.$eventBus.$emit('SET-LOADER',true);
                    axios.post('/api/products/sendProposal', toSend).then(response => {
                        this.$eventBus.$emit('SET-LOADER',false);
                    }).catch(e => {
                        this.$eventBus.$emit('SET-LOADER',false);
                        this.$eventBus.$emit('ALERT-ERROR', translate('decking.proposal.fail'));
                    });
                } else if(this.next == 'area-form') {
                    this.$router.push({
                        name: this.next,
                    })
                } else {
                    this.$router.push({
                        name: this.next,
                    })
                }

            }

      },
    }
</script>
