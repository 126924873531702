<template>
    <BaseLayout :titleposa="titleposa()" :title="$attrs.title" :subtitle="$attrs.subtitle" :steps="$attrs.steps" :variant="'default'"  :prev='prevStep()' :next="nextStep()">
        <div class="w-100 form-template-1 h-100 d-flex flex-column">
            <div class="row h-100 scroll">
                <div class="col-12 d-flex align-items-center">
                    <div class="w-100 row">
                        <div class="col-lg-4 order-2 order-lg-1">
                            <div class="form-template-1">
                                <div class="mb-5 d-block" v-for="size in tile_sizes">
                                    <label class="text--lg mb-2 fw--bold text-uppercase" for="">{{translate(size.name)}}</label>
                                    <div class="d-flex">
                                        <input @click="setSelectedSize(size)" @keypress="isNumber($event)" type="number" min="1" class="c-input text--md d-block col-12 col-lg-5" :class="[isSizeSelected(size) ? 'active' : '']" v-model="size.value" name="value" value="">
                                        <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                            <p class="text--sm fw--bold fc--blue px-1">mm</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="offset-lg-0 col-lg-6 order-lg-2 order-1">
                            <div class="w-100 d-flex align-items-center justify-content-center">
                                <div class="tile-letters-box ratio ratio-1x1 position-relative w-100 h-50">
                                    <img :src="require('@/assets/images/svg/tile.svg')" class="d-block w-100 h-75">
                                    <div v-for="size in tile_sizes" :style="getLetterPosition(size)" class="tile-letters-box--letter" :class="[isSizeSelected(size) ? 'active' : '']">
                                        {{size.id}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
    import axios from 'axios';
    import {
        required,
        minLength
    } from 'vuelidate/lib/validators';
    export default {
        name: 'TileSize',
        data: function() {
            return {
                //FORMS
                selected_size:null,
                tile_sizes: [
                    {id:'a', name:this.translate('decking.lato.a'), value:(this.$store.state.tile && this.$store.state.tile[0]) ? this.$store.state.tile[0].value : 400},
                    {id:'b', name:this.translate('decking.lato.b'), value:(this.$store.state.tile && this.$store.state.tile[1]) ? this.$store.state.tile[1].value : 400},
                    {id:'h', name:this.translate('decking.tile.spessore'), value:(this.$store.state.tile && this.$store.state.tile[2]) ? this.$store.state.tile[2].value : 20}
                ]
            }
        },
        methods: {
            getLetterPosition(size){
                switch (size.id) {
                    case 'a':
                        return 'top:23%; left:24%;';
                        break;
                    case 'b':
                        return 'top:25%; left:64%;';
                        break;
                    case 'h':
                        return 'top:50%; left:26%;';
                        break;
                }
            },
            setSelectedSize(size){
                this.selected_size = size;
            },
            isSizeSelected(size){
                if (this.selected_size && this.selected_size.id) {
                    if (size.id == this.selected_size.id) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return  false;
                }
            },
            prevStep(){

                if (this.$store.state.magatellitenant.length == 0) {
                    return 'select-pose';
                } else {
                    return 'select-magatello';
                }
            },
            nextStep(){
                return 'area-form';
            },
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode != 43 && charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            titleposa() {
                if (this.$store.state.pose_type.id == 1) {
                    return 'Paving';
                } else {
                    return 'Decking';
                }
            }
        },
        beforeMount: function() {
            // console.dir(this.tile_sizes);
            console.log("in montaggio");
            if (!this.$store.state.tile) {
                this.$store.commit('setTile', this.tile_sizes);
            }
        },
        watch: {
            'tile_sizes': {
                handler: function() {
                    console.log("aggiorno inserendo newVal "+this.tile_sizes);
                    this.$store.commit('setTile', this.tile_sizes);
                },
                deep: true
            }
        }
    }
</script>
