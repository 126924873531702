import "@/assets/css/app.scss";

import Vue from 'vue';
import Vuex from 'vuex';
import gsap from "gsap";
import moment from 'moment';
import Lottie from 'lottie-web';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import vSelect from 'vue-select'
import axios from 'axios';
import CountryFlag from 'vue-country-flag'

//SECTIONS/LAYOUTS
import App from './App.vue';
import BaseLayout from './components/base/BaseLayout.vue';

import router from './router'
import store from './store'

import {
  translate
} from './VueTranslation/Translation';
import _ from 'lodash'

Vue.component('v-select', vSelect);
Vue.component('BaseLayout', BaseLayout);

Vue.component('country-flag', CountryFlag)

Vue.prototype.moment = moment;

Vue.mixin({
  created: function() {
    this.$gsap = gsap;

    axios.defaults.headers.common['x-tenant-company'] = this.builderCompanyUuid;
    axios.defaults.headers.common['x-tenant-language'] = localStorage.getItem('build-spa-lang');

    if (!localStorage.getItem('build-spa-lang')) {
      let [firstLang] = this.builderLang;
      this.switchLang(firstLang)
    }

    document.title = this.resolveFromConfig('builder_name')[this.currentLocaleLang]
  },
  computed: {
    builderTentantMailerConfigIsValid() {
      return window._s;
    },
    builderCompanyUuid() {
      return window._x;
    },
    builderTentantConfig() {
      return window._c;
    },
    builderTentantTranslation() {
      return window._t;
    },
    builderLang() {
      return JSON.parse(this.builderTentantTranslation)['iso']
    },
    currentLocaleLang() {
      return localStorage.getItem('build-spa-lang')
    }
  },
  methods: {
    translate(key, replacements = {}) {
      return translate(key, replacements)
    },
    resolveFromConfig(key, ddefault = '') {
      var config = JSON.parse(this.builderTentantConfig)
      var resource = config[key] || null;

      if (!resource) {
        return ddefault
      }

      return resource;
    },
    switchLang(lang) {
      localStorage.setItem('build-spa-lang', lang)
      setTimeout(() => location.reload())
    }
  },
});

Vue.use(Vuelidate);
Vue.use(Vuex);

// Vue.config.productionTip = false;
// Vue.config.devtools = (process.env.NODE_ENV != 'production')

Vue.config.productionTip = true;
Vue.config.devtools = true;

Vue.prototype.$eventBus = new Vue();

const vm = new Vue({
  render: h => h(App),
  router,
  store,
  components: {
    App
  }
});

vm.$mount('#app')

export {
  vm
}
