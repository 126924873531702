<template>
    <BaseLayout :titleposa="titleposa()" :title="$attrs.title" :subtitle="$attrs.subtitle" :steps="$attrs.steps" :variant="'default'"  :prev='prevStep()' :next="nextStep()">
        <div class="w-100 form-template-1 h-100 d-flex flex-column">
            <div class="row scroll h-100">
                <div class="col-12">
                    <div v-if="products.length > 0" class="c-table row g-0 pb-5">
                        <div class="col-3 d-none d-lg-flex flex-column">
                            <div class="ratio w-50 mx-auto" style="height: 101px;">
                            </div>
                            <p class="text--xl fw--bold text-uppercase fc--blue mb-3">&nbsp;</p>

                            <div class="td">
                                <p class="text--md text-uppercase fc--blue">{{translate('decking.product.portata')}}</p>
                            </div>
                            <div class="td">
                                <p class="text--md text-uppercase fc--blue">{{translate('decking.product.altezza')}}</p>
                            </div>
                                <div class="td">
                                    <p class="text--md text-uppercase fc--blue">{{translate('decking.product.possprol')}}</p>
                                </div>
                                <div class="td">
                                    <p class="text--md text-uppercase fc--blue">{{translate('decking.product.altezzareg')}}</p>
                                </div>
                            <div class="td">
                                <p class="text--md text-uppercase fc--blue">{{translate('decking.product.diambase')}}</p>
                            </div>
                                <div class="td">
                                    <p class="text--md text-uppercase fc--blue">{{translate('decking.product.gomma')}}</p>
                                </div>
                                <div class="td">
                                    <p class="text--md text-uppercase fc--blue">{{translate('decking.product.finlat')}}</p>
                                </div>
                        </div>
                        <div class="col-lg-9 col-10 offset-lg-0 offset-1">
                            <div class="row">
                                <div v-for="(pt,index) in products" :class="'col-lg-'+(12/products.length )+' col-12 offset-lg-0 mb-5 pb-lg-0'">
                                    <div @click="setProduct(pt)" class="product d-block w-100 text-center" :class="[isProductSelected(pt) ? 'active' : '', pt.prodok == 'N' ? 'disabled' : '']">
                                        <div class="ratio w-50 mx-auto" style="height: 97px">
                                            <!-- <img :src="require('@/assets/images/svg/product_' + pt.id + '.svg')" class="d-block px-4 w-100"> -->
                                        </div>
                                        <p class="text--xl fw--bold text-uppercase fc--blue mb-3" style="margin-bottom: 20px !important;">{{pt.name}}</p>
                                        <div class="td justify-content-center flex-column">
                                        <p class="text--sm fc--blue fw--bold mb-1 d-block d-lg-none text-uppercase">{{translate('decking.product.portata')}}</p>
                                            <p class="text--md fc--blue">{{translate('decking.summary.portatafinoa')}} {{pt.pormax}} kg</p>
                                            <!-- <p class="text--md fc--blue" v-if="pt.pormin = 0"> - </p> -->
                                        </div>
                                        <div class="td justify-content-center flex-column">
                                        <p class="text--sm fc--blue fw--bold mb-1 d-block d-lg-none text-uppercase">{{translate('decking.product.altezza')}}</p>
                                            <p class="text--md fc--blue">{{translate('decking.summary.apartireda')}} {{pt.hmin}}</p>
                                        </div>

                                        <div class="td justify-content-center flex-column">
                                        <p class="text--sm fc--blue fw--bold mb-1 d-block d-lg-none text-uppercase">{{translate('decking.product.possprol')}}</p>
                                            <p class="text--md fc--blue">{{pt.modular_height}}</p>
                                        </div>
                                        <div class="td justify-content-center flex-column">
                                        <p class="text--sm fc--blue fw--bold mb-1 d-block d-lg-none text-uppercase">{{translate('decking.product.altezzareg')}}</p>
                                            <p class="text--md fc--blue">{{pt.variable_height}}</p>
                                        </div>

                                        <div class="td justify-content-center flex-column">
                                        <p class="text--sm fc--blue fw--bold mb-1 d-block d-lg-none text-uppercase">{{translate('decking.product.diambase')}}</p>
                                            <p class="text--md fc--blue">{{pt.dbase}} mm</p>
                                        </div>

                                            <div class="td justify-content-center flex-column">
                                            <p class="text--sm fc--blue fw--bold mb-1 d-block d-lg-none text-uppercase">{{translate('decking.product.gomma')}}</p>
                                                <p class="text--md fc--blue">{{pt.rubber}}</p>
                                            </div>
                                            <div class="td justify-content-center flex-column">
                                            <p class="text--sm fc--blue fw--bold mb-1 d-block d-lg-none text-uppercase">{{translate('decking.product.finlat')}}</p>
                                                <p class="text--md fc--blue">{{pt.side_finish_accessories}}</p>
                                            </div>

                                        <button class="btn btn-outline-primary btn-personalized-outline mb-3 mt-3 text--md text-uppercase px-4" type="button">{{translate('decking.product.seleziona')}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
    import axios from 'axios';
    import {
        required,
        minLength
    } from 'vuelidate/lib/validators';
    import {
      translate
    } from '@/VueTranslation/Translation';
    export default {
        name: 'ProductType',
        data: function() {
            return {
                //FORMS
                // selected_product: null,
                products: [
                    // {
                    //     id: 'a',
                    //     title: 'Archimede',
                    //     reach: 'da xx ton a xx ton',
                    //     height:'da 10 a 1.000 mm',
                    //     modular_height:'234 mm',
                    //     variable_height:'1.000 mm',
                    //     base_diameter:'200 mm (314 cm2)',
                    //     rubber:'Sì - ACCESSORIO',
                    //     side_finish_accessories:'CHIUSURA e PERIMETRALE',
                    //     image: '@/assets/images/svg/product-arkimede.svg'
                    //
                    // },
                    // {
                    //     id: 'h',
                    //     title: 'Hercules',
                    //     image: '@/assets/images/svg/product-hercules.svg',
                    //     reach: 'da xx ton a xx ton',
                    //     height:'da 10 a 1.000 mm',
                    //     modular_height:'234 mm',
                    //     variable_height:'1.000 mm',
                    //     base_diameter:'200 mm (314 cm2)',
                    //     rubber:'Sì - ACCESSORIO',
                    //     side_finish_accessories:'CHIUSURA e PERIMETRALE',
                    // },
                    // {
                    //     id: '3d',
                    //     title: '3D Scopico',
                    //     image: '@/assets/images/svg/product-3d.svg',
                    //     reach: 'da xx ton a xx ton',
                    //     height:'da 10 a 1.000 mm',
                    //     modular_height:'234 mm',
                    //     variable_height:'1.000 mm',
                    //     base_diameter:'200 mm (314 cm2)',
                    //     rubber:'Sì - ACCESSORIO',
                    //     side_finish_accessories:'CHIUSURA e PERIMETRALE',
                    // },
                ],

            }
        },
        methods: {
            setProduct(pt){
                this.$store.commit('setProduct', pt);

            },
            isProductSelected(product){
                if (this.$store.state.product && this.$store.state.product.id) {
                    if (product.id == this.$store.state.product.id) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return  false;
                }
            },
            prevStep(){
                // if (this.$store.state.pose_type.id == 1 && this.$store.state.magatello.id == 1 ) {
                    return 'area-size';
                // } else {
                //     return 'pose-direction';
                // }
            },
            nextStep(){
                return 'system';
            },
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode != 43 && charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            titleposa() {
                if (this.$store.state.pose_type.id == 1) {
                    return 'Paving';
                } else {
                    return 'Decking';
                }
            }
        },
        beforeMount: function() {
            this.$eventBus.$emit('SET-LOADER',true);
            toSend = {};
            toSend.lm=this.$store.state.tile[0].value;
            toSend.pm=this.$store.state.tile[1].value;

            toSend.ls=this.$store.state.area.area_sizes[0].value;
            toSend.ps=this.$store.state.area.area_sizes[1].value;
            if(this.$store.state.area.area_sizes[2]) {
                toSend.ls1=this.$store.state.area.area_sizes[3].value;
                toSend.ps1=this.$store.state.area.area_sizes[2].value;
            }
            // parte nuova per veert ed orizzontale fusi con spaccamattone o normale
            // 0 e 2 sono 0 (normale) altrimenti 1 = spaccamattone
            let tileorientation = 0; let posedirection = 0; let disposizionemateriale = 0;
            if(this.$store.state.tile_orientation.id == 1 || this.$store.state.tile_orientation.id == 3) {
                tileorientation = 1;    // verticale
                posedirection = 1;
            }
            if(this.$store.state.tile_orientation.id == 2 || this.$store.state.tile_orientation.id == 3) {
                disposizionemateriale = 1;
            }

            toSend.orientation = tileorientation;
            toSend.disposizionemateriale = disposizionemateriale;
            // send also unload
            toSend.scaricox = this.$store.state.area.unload_x;
            toSend.scaricoy = this.$store.state.area.unload_y;
            toSend.scaricoh = this.$store.state.area.unload_h;
            toSend.isPuntoScarico = this.$store.state.area.isPuntoScarico;
            toSend.isSconnesso = this.$store.state.area.isSconnesso;
            toSend.h1 = this.$store.state.area.height_a;
            toSend.h2 = this.$store.state.area.height_b;
            toSend.h3 = this.$store.state.area.height_c;
            toSend.h4 = this.$store.state.area.height_d;
            toSend.posetype = this.$store.state.pose_type.id;

            axios.post('/api/products/calc/'+ this.$store.state.magatello.type, toSend).then(response => {
                this.pose_directions[2].qty = response.data["spacca-no-centrale"];
                this.pose_directions[3].qty = response.data["spacca-centrale"];
                this.pose_directions[0].qty = response.data["tradizionale-no-centrale"];
                this.pose_directions[1].qty = response.data["tradizionale-centrale"];
                // this.matrice = response.data("matrice");
                this.$store.commit('setCalcs', response.data);
                this.$eventBus.$emit('SET-LOADER',false);
            }).catch(e => {
                this.$eventBus.$emit('SET-LOADER',false);
                // this.$eventBus.$emit('ALERT-ERROR','Errore di rete.');
            });

            this.$eventBus.$emit('SET-LOADER',true);
            let hok = 0;
            // let hok=this.$store.state.area.height-this.$store.state.tile[2].value;

            if(!this.$store.state.area.isPuntoScarico) {
                let arrayaltezze = [this.$store.state.area.height_a,this.$store.state.area.height_b,this.$store.state.area.height_c,this.$store.state.area.height_d];
                hok = arrayaltezze.reduce((a, b) => Math.min(a, b));
            } else {
                hok = this.$store.state.area.unload_h;
                if(this.$store.state.magatello.id==1) {
                    hok=hok-30;
                }
            }
            hok = hok - this.$store.state.tile[2].value;
            console.log("cerco...."+this.$store.state.area.isPuntoScarico+" - "+hok);

            let toSend = {};
            toSend.lm=this.$store.state.tile[0].value;
            toSend.pm=this.$store.state.tile[1].value;

            toSend.ls=this.$store.state.area.area_sizes[0].value;
            toSend.ps=this.$store.state.area.area_sizes[1].value;
            if(this.$store.state.area.area_sizes[2]) {
                toSend.ls1=this.$store.state.area.area_sizes[3].value;
                toSend.ps1=this.$store.state.area.area_sizes[2].value;
            }
            toSend.orientation = tileorientation;
            toSend.disposizionemateriale = disposizionemateriale;
            toSend.h1 = this.$store.state.area.height_a;
            toSend.h2 = this.$store.state.area.height_b;
            toSend.h3 = this.$store.state.area.height_c;
            toSend.h4 = this.$store.state.area.height_d;
            toSend.hsupporto = this.$store.state.tile[2].value;
            toSend.isPuntoScarico = this.$store.state.area.isPuntoScarico;
            toSend.scaricox = this.$store.state.area.unload_x;
            toSend.scaricoy = this.$store.state.area.unload_y;
            toSend.scaricoh = this.$store.state.area.unload_h;
            if(this.$store.state.magatello.id == 0) {
                toSend.posetype = posedirection;
            } else if(this.$store.state.magatello.id > 1) {
                // se ha scelto il tipo di magatello, quindi è passato dal decking
                // toSend.disposizionemateriale = posedirection;
                toSend.tipomagatello = this.$store.state.magatello.id;
            }
            toSend.posetype = this.$store.state.pose_type.id;

            axios.post('/api/products/calc/'+ this.$store.state.magatello.type, toSend).then(response => {
                // non sovrascrivo il calcolo dei livelli
                this.$store.commit('setCalcs', response.data);
                this.$eventBus.$emit('SET-LOADER',false);
            }).catch(e => {
                this.$eventBus.$emit('SET-LOADER',false);
                this.$eventBus.$emit('ALERT-ERROR',translate('decking.info.networkerror'));
            });

            this.$eventBus.$emit('SET-LOADER',true);
            axios.get('/api/products/search/'+ hok+'/'+this.$store.state.leak).then(response => {
                this.products = response.data.products;
                let activableProducts = response.data.products.filter(product => product.prodok == 'S');
                if (activableProducts.length > 0) {
                    if (!this.$store.state.product) {
                        this.$store.commit('setProduct', activableProducts[0]);
                     }
                } else {
                    this.$eventBus.$emit('ALERT-ERROR',translate('decking.info.noproducts'));
                    this.$router.push({
                        name: 'tiles-orientation',
                    })
                }
                this.$eventBus.$emit('SET-LOADER',false);
            }).catch(e => {
                this.$eventBus.$emit('SET-LOADER',false);
                this.$eventBus.$emit('ALERT-ERROR',translate('decking.info.networkerror'));
            });

        }
    }
</script>
