<template>
    <BaseLayout :titleposa="titleposa()" :title="$attrs.title" :subtitle="$attrs.subtitle" :steps="$attrs.steps" :variant="'default'"  :prev='prevStep()' :next="nextStep()">
        <div class="w-100 form-template-1 h-100 d-flex flex-column">
            <div class="row h-100 scroll">
                <div class="col-12 d-flex align-items-center">
                    <div class="w-100 row">
                        <div @click="setActiveDirection(pt)" v-for="pt in pose_directions" class="col-lg-4 mb-5 offset-lg-1 col-12" v-if="pt.qty != 0">
                            <div class="text-center">
                                <img v-if="!$store.state.pose_direction || pt.id != $store.state.pose_direction.id" :src="pt.image" class="d-block h-9 mx-auto w-75">
                                <img v-if="$store.state.pose_direction && pt.id == $store.state.pose_direction.id" :src="pt.image_active" class="d-block h-9 mx-auto w-75">
                                <p class="text--xl fw--bold text-uppercase fc--black mb-3 mt-3">{{translate(pt.description)}}</p>
                                <!-- <p v-if="pt.qty" class="text--md fw--bold fc--black mt-3">{{translate('decking.info.qty')}}: {{pt.qty}} {{translate('decking.info.pieces')}}</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
    import axios from 'axios';
    import {
        required,
        minLength
    } from 'vuelidate/lib/validators';
    export default {
        name: 'PoseDirection',
        data: function() {
            if(this.$store.state.pose_type.id == 0) {
                return {
                    //FORMS
                    pose_directions: [
                        {
                            id: 0,
                            title: 'A.',
                            description: this.translate('decking.info.simple'),
                            image: require('@/assets/images/png/posasemplice-off.png'),
                            image_active: require('@/assets/images/png/posasemplice-on.png'),
                            qty:null
                        },
                        {
                            id: 2,
                            title: 'C.',
                            description: this.translate('decking.info.spacca'),
                            image: require('@/assets/images/png/posaspaccamattone-off.png'),
                            image_active: require('@/assets/images/png/posaspaccamattone-on.png'),
                            qty:null
                        }
                    ]
                }
            } else {
                return {
                    //FORMS
                    pose_directions: [
                        {
                            id: 0,
                            title: 'A.',
                            description: this.translate('decking.info.simple'),
                            image: require('@/assets/images/png/semplice-off.png'),
                            image_active: require('@/assets/images/png/semplice-on.png'),
                            qty:null
                        },
                        {
                            id: 1,
                            title: 'B.',
                            description: this.translate('decking.info.simplesupp'),
                            image: require('@/assets/images/png/semplice-supporto-off.png'),
                            image_active: require('@/assets/images/png/semplice-supporto-on.png'),
                            qty:null
                        },
                        {
                            id: 2,
                            title: 'C.',
                            description: this.translate('decking.info.spacca'),
                            image: require('@/assets/images/png/spaccamattone-off.png'),
                            image_active: require('@/assets/images/png/spaccamattone-on.png'),
                            qty:null
                        },
                        {
                            id: 3,
                            title: 'D.',
                            description: this.translate('decking.info.spaccacentro'),
                            image: require('@/assets/images/png/spaccamattone-supporto-off.png'),
                            image_active: require('@/assets/images/png/spaccamattone-supporto-on.png'),
                            qty:null
                        },
                    ],
                }
            }
        },
        beforeMount: function() {
            this.$eventBus.$emit('SET-LOADER',true);
            let toSend = {};
            toSend.lm=this.$store.state.tile[0].value;
            toSend.pm=this.$store.state.tile[1].value;

            toSend.ls=this.$store.state.area.area_sizes[0].value;
            toSend.ps=this.$store.state.area.area_sizes[1].value;
            if(this.$store.state.area.area_sizes[2]) {
                toSend.ls1=this.$store.state.area.area_sizes[3].value;
                toSend.ps1=this.$store.state.area.area_sizes[2].value;
            }
            toSend.orientation = this.$store.state.tile_orientation.id;
            // send also unload
            toSend.scaricox = this.$store.state.area.unload_x;
            toSend.scaricoy = this.$store.state.area.unload_y;
            toSend.scaricoh = this.$store.state.area.unload_h;
            toSend.isPuntoScarico = this.$store.state.area.isPuntoScarico;
            toSend.isSconnesso = this.$store.state.area.isSconnesso;
            toSend.h1 = this.$store.state.area.height_a;
            toSend.h2 = this.$store.state.area.height_b;
            toSend.h3 = this.$store.state.area.height_c;
            toSend.h4 = this.$store.state.area.height_d;
            toSend.posetype = this.$store.state.pose_type.id;

            axios.post('/api/products/calc/'+ this.$store.state.magatello.type, toSend).then(response => {
                this.pose_directions[2].qty = response.data["spacca-no-centrale"];
                this.pose_directions[3].qty = response.data["spacca-centrale"];
                this.pose_directions[0].qty = response.data["tradizionale-no-centrale"];
                this.pose_directions[1].qty = response.data["tradizionale-centrale"];
                // this.matrice = response.data("matrice");
                this.$store.commit('setCalcs', response.data);
                this.$eventBus.$emit('SET-LOADER',false);
            }).catch(e => {
                this.$eventBus.$emit('SET-LOADER',false);
                // this.$eventBus.$emit('ALERT-ERROR','Errore di rete.');
            });
        },
        mounted: function(){
            if (!this.$store.state.pose_direction) {
                if(this.$store.state.pose_type.id == 0) {
                    this.$store.commit('setPoseDirection', this.pose_directions[1]);
                } else {
                    this.$store.commit('setPoseDirection', this.pose_directions[0]);
                }
            }
        },
        methods: {
            setActiveDirection(pt){
                this.$store.commit('setPoseDirection', pt);
            },
            prevStep(){
                return 'tiles-orientation';
            },
            nextStep(){
                return 'product-type';
            },
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode != 43 && charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            titleposa() {
                if (this.$store.state.pose_type.id == 1) {
                    return 'Paving';
                } else {
                    return 'Decking';
                }
            }
        }
    }
</script>
