import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeScreen from './../components/HomeScreen.vue';
import SelectPose from './../components/SelectPose.vue';
import TileSize from './../components/TileSize.vue';
import SelectMaterial from './../components/Material.vue';
import SelectPavDisposition from './../components/SelectPavDisposition.vue';
import ProductType from './../components/ProductType.vue';
import PoseDirection from './../components/PoseDirection.vue';
import AreaSize from './../components/AreaSize.vue';
import Summary from './../components/Summary.vue';
import AreaForm from './../components/AreaForm.vue';
import SelectMagatello from './../components/SelectMagatello.vue';
import TilesOrientation from './../components/TilesOrientation.vue';
import MySystem from './../components/MySystem.vue';
import { translate } from './../VueTranslation/Translation';

Vue.use(VueRouter)

const routes = [{
    name: 'home-screen',
    path: '/',
    component: HomeScreen,
    props: {
        sidebar: {
            show: false,
        }
    }
}, {
    name: 'select-pose',
    path: '/posa',
    component: SelectPose,
    props: {
        sidebar: {
            show: true,
            title: translate('steps.posa.title'),
            subtitle: translate('steps.posa.subtitle')
        },
        title: translate('steps.posapage.title'),
        subtitle: translate('steps.posapage.subtitle')
    }
},
{
    name: 'select-magatello',
    path: '/magatello',
    component: SelectMagatello,
    props: {
        sidebar: {
            show: true,
            title: translate('steps.supporti.title'),
            subtitle: translate('steps.supporti.subtitle')
        },
        title: translate('steps.supportipage.title'),
        subtitle: translate('steps.supportipage.subtitle')
    }
}, {
    name: 'select-floor',
    path: '/pavimentazione',
    component: SelectMaterial,
    props: {
        sidebar: {
            show: true,
            title: translate('steps.materiale.title'),
            subtitle: translate('steps.materiale.subtitle')
        },
        title: translate('steps.materialepage.title'),
        subtitle: translate('steps.materialepage.subtitle')
    }
}, {
    name: 'select-orientationanddisposition',
    path: '/pavimentoeorientamento',
    component: SelectPavDisposition,
    props: {
        sidebar: {
            show: true,
            title: translate('steps.orientationanddisposition.title'),
            subtitle: translate('steps.orientationanddisposition.subtitle')
        },
        title: translate('steps.orientationanddisposition.title'),
        subtitle: translate('steps.orientationanddisposition.subtitle')
    }
}, {
    name: 'area-form',
    path: '/forma-area',
    component: AreaForm,
    props: {
        sidebar: {
            show: true,
            title: translate('steps.area.title'),
            subtitle: translate('steps.area.subtitle')
        },
        title: translate('steps.areapage.title'),
        subtitle: translate('steps.areapage.subtitle')
    }
}, {
    name: 'area-size',
    path: '/dimensioni-area',
    component: AreaSize,
    props: {
        sidebar: {
            show: true,
            title: translate('steps.areasize.title'),
            subtitle: translate('steps.areasize.subtitle')
        },
        title: translate('steps.areasizepage.title'),
        subtitle: translate('steps.areasizepage.subtitle')
    }
},
//  {
//     name: 'tiles-orientation',
//     path: '/orientamento-mattonelle',
//     component: TilesOrientation,
//     props: {
//         sidebar: {
//             show: true,
//             title: translate('steps.tiles.title'),
//             subtitle: translate('steps.tiles.subtitle')
//         },
//         title: translate('steps.tilespage.title'),
//         subtitle: translate('steps.tilespage.subtitle')
//     }
// }, {
//     name: 'pose-direction',
//     path: '/direzione-di-posa',
//     component: PoseDirection,
//     props: {
//         sidebar: {
//             show: true,
//             title: translate('steps.pose.title'),
//             subtitle: translate('steps.pose.subtitle')
//         },
//         title: translate('steps.posepage.title'),
//         subtitle: translate('steps.posepage.subtitle')
//     }
// },
{
    name: 'product-type',
    path: '/prodotto',
    component: ProductType,
    props: {
        sidebar: {
            show: true,
            title: translate('steps.product.title'),
            subtitle: translate('steps.product.subtitle')
        },
        title: translate('steps.productpage.title'),
        subtitle: translate('steps.productpage.subtitle')
    }
}, {
    name: 'system',
    path: '/sistema',
    component: MySystem,
    props: {
        sidebar: {
            show: true,
            title: translate('steps.system.title'),
            subtitle: translate('steps.system.subtitle')
        },
        title: translate('steps.systempage.title'),
        subtitle: translate('steps.systempage.subtitle')
    }
}];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router