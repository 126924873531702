<template>
    <BaseLayout :titleposa="titleposa()" :title="$attrs.title" :subtitle="$attrs.subtitle" :steps="$attrs.steps" :variant="'default'"  :prev='prevStep()' :next="nextStep()">
        <div class="w-100 form-template-1 h-100 d-flex flex-column">
            <div class="row h-100 scroll">
                <div class="col-12 d-flex align-items-center">
                    <div class="w-100 row">
                        <div @click="setTileOrientation(mt)" v-for="mt in tile_orientations" class="d-block col-lg-3 mb-5 mb-lg-0 h-auto text-center">
                            <div class="px-5 mb-5">
                                <div class="">
                                    <img v-if="!$store.state.tile_orientation || mt.id != $store.state.tile_orientation.id" :src="mt.image" class="d-block w-100 sm:w-100 mx-auto h-auto px-lg-0">
                                    <img v-if="$store.state.tile_orientation && mt.id == $store.state.tile_orientation.id" :src="mt.image_active" class="d-block w-100 sm:w-100 mx-auto h-auto px-lg-0">
                                </div>
                                <p class="text--xl fw--bold text-uppercase fc--black mt-3 mb-1">{{translate(mt.description)}}</p>
                                <p class="text--md fc--black">{{translate(mt.subtitle)}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
    import axios from 'axios';
    export default {
        name: 'SelectPavDisposition',
        data: function() {
            return {
                tile_orientations: [
                    {
                        id: 0,
                        title: 'O.',
                        description: this.translate('decking.orizzontal1.title'),
                        subtitle: this.translate('decking.orizzontal1.text'),
                        image: require('@/assets/images/png/orizz-1_off.png'),
                        image_active: require('@/assets/images/png/orizz-1_on.png'),
                    },
                    {
                        id: 1,
                        title: 'V.',
                        description: this.translate('decking.vertical1.title'),
                        subtitle: this.translate('decking.vertical1.text'),
                        image: require('@/assets/images/png/vert-1_off.png'),
                        image_active: require('@/assets/images/png/vert-1_on.png'),
                    },
                    {
                        id: 2,
                        title: 'O.',
                        description: this.translate('decking.orizzontal2.title'),
                        subtitle: this.translate('decking.orizzontal2.text'),
                        image: require('@/assets/images/png/orizz-2_off.png'),
                        image_active: require('@/assets/images/png/orizz-2_on.png'),
                    },
                    {
                        id: 3,
                        title: 'V.',
                        description: this.translate('decking.vertical2.title'),
                        subtitle: this.translate('decking.vertical2.text'),
                        image: require('@/assets/images/png/vert-2_off.png'),
                        image_active: require('@/assets/images/png/vert-2_on.png'),
                    }
                ],
                pose_directions: [
                        {
                            id: 0,
                            title: 'A.',
                            description: this.translate('decking.info.simple'),
                            image: require('@/assets/images/png/posasemplice-off.png'),
                            image_active: require('@/assets/images/png/posasemplice-on.png'),
                            qty:null
                        },
                        {
                            id: 2,
                            title: 'C.',
                            description: this.translate('decking.info.spacca'),
                            image: require('@/assets/images/png/posaspaccamattone-off.png'),
                            image_active: require('@/assets/images/png/posaspaccamattone-on.png'),
                            qty:null
                        }
                    ]
            }
        },
        mounted: function() {
            if (!this.$store.state.tile_orientation) {
                this.setTileOrientation(this.tile_orientations[0]);
            }
            if (!this.$store.state.pose_direction) {
                if(this.$store.state.pose_type.id == 0) {
                    this.$store.commit('setPoseDirection', this.pose_directions[1]);
                } else {
                    this.$store.commit('setPoseDirection', this.pose_directions[0]);
                }
            }
        },
        methods: {
            setTileOrientation(orientation){
                this.$store.commit('setTileOrientation', orientation);
            },
            prevStep(){
                return 'select-floor';
            },
            nextStep(){
                //Se paving con magatello,  no posa pavimento
                return 'area-form'
            },
            titleposa() {
                if (this.$store.state.pose_type.id == 1) {
                    return 'Paving';
                } else {
                    return 'Decking';
                }
            }
        }
    }
</script>
