import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        tile: null,
        form: null,
        area: null,
        magatello: null,
        pose_type: null,
        pose_direction: null,
        tile_orientation: null,
        product: null,
        system: null,
        calcs: null,
        contacts: null,
        image_prodotto: null,
        fughe: null,
        magatellitenant: [],
        extensions: null,
        accessori: null,
        matrice: null,
        infomagatello: null,
        leak: null,
        keyutente: null
    },
    mutations: {
        setImageProdotto(state, payload) {
            state.image_prodotto = payload;
        },
        setProposalId(state,payload) {
            state.proposalid = payload;
        },
        setContacts(state, payload) {
            state.contacts = payload;
        },
        setCalcs(state, payload) {
            state.calcs = payload;
        },
        setTile(state, payload) {
            console.log("chiamata setTile");
            state.tile = payload;
        },
        setMatrice(state, payload){
            state.matrice = payload;
        },
        setInfoMagatello(state,payload){
            state.infomagatello = payload;
        },
        setFughe(state, payload) {
            state.fughetenant = payload;
        },
        setUser(state, payload) {
            state.keyutente = payload;
        },
        setMagatelli(state, payload) {
            state.magatellitenant = payload;
        },
        setProdotti(state, payload) {
            state.livingproducts = payload;
        },
        setForm(state, payload) {
            state.form = payload;
        },
        setArea(state, payload) {
            state.area = payload;
        },
        setLeak(state, payload) {
            console.log("ancora setleak "+payload);
            state.leak = payload;
        },
        setMagatello(state, payload) {
            state.magatello = payload;
        },
        setPoseType(state, payload) {
            state.pose_type = payload;
        },
        setPoseDirection(state, payload) {
            state.pose_direction = payload;
        },
        setTileOrientation(state, payload) {
            state.tile_orientation = payload;
        },
        setProduct(state, payload) {
            state.product = payload;
        },
        setLivingProduct(state, payload) {
            state.livingproduct = payload;
        },
        setSystem(state, payload) {
            state.system = payload;
        },
        setExtensions(state, payload) {
            state.extensions = payload;
        },
        setAccessories(state, payload) {
            state.accessori = payload;
        },
        setLivingAccessories(state, payload) {
            state.livingaccessori = payload;
        },
    }
})
