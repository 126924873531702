<template>
    <BaseLayout :titleposa="titleposa()" :title="$attrs.title" :subtitle="$attrs.subtitle" :steps="$attrs.steps" :variant="'default'"  :prev='prevStep()' :next="nextStep()">
        <div class="w-100 form-template-1 h-100 d-flex flex-column">
            <div class="row scroll h-100">
                <div class="col-12">

                    <div v-if="products.length > 0" class="row">
                        <!-- <div class="col-lg-3 bg-summary text-center">
                            <div class="row">
                                <div class="col-12">
                                    <p class="text--lg text-uppercase fc--black fw--bold text-uppercase mt-2 mb-3">{{translate('decking.info.ituoidati')}}</p>
                                </div>
                            </div>
                            <div class="row"  v-if="!$store.state.livingproduct">
                                <div class="col-lg-4 col-12 text-center">
                                    <img src="/images/svg/tile.svg" class="d-block w-100 h-100 mx-auto mb-0">
                                </div>
                                <div class="col-lg-8 text-start">

                                    <p class="text--md fw--bold fc--blue">{{translate('steps.mattonella.title')}}</p>
                                    <p class="text--sm fc--blue mb-4">
                                        <span v-for="tile in $store.state.tile">{{tile.name+': '+tile.value+'m'}}<br></span>
                                    </p>
                                </div>
                            </div>
                            <div class="row"  v-if="$store.state.livingproduct">
                                <div class="col-lg-4 col-12 text-center">
                                    <img :src="$store.state.livingproduct.image" class="d-block w-100 mx-auto mb-0">
                                </div>
                                <div class="col-lg-8 text-start">

                                    <p class="text--md fw--bold fc--blue">{{translate('steps.livingproduct.title')}}</p>
                                    <p class="text--sm fc--blue mb-4">
                                        <span class="text--md text-uppercase fw--bold fc--black d-block">{{ $store.state.livingproduct.descrizione}}</span><br />
                                        <span v-for="tile in $store.state.tile">{{tile.name+': '+tile.value+'mm'}}<br></span>
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-12 text-center">
                                    <img :src="$store.state.form.image" class="d-block w-100 h-auto mx-auto mb-0">
                                </div>
                                <div class="col-lg-8 text-start pt-3">
                                    <p class="text--sm fw--bold fc--blue">{{translate('decking.summary.area')}}</p>
                                    <p class="text--sm fc--blue mb-4">
                                        <span v-for="area_size in $store.state.area.area_sizes">{{area_size.name+': '+area_size.value+'mm'}}<br/></span>
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-12 text-center">
                                    <img v-if="$store.state.pose_direction" :src="$store.state.pose_direction.image" class="d-block w-100 h-auto mx-auto mb-0">
                                </div>
                                <div class="col-lg-8 text-start pt-3">
                                    <p v-if="$store.state.pose_direction" class="text--md fw--bold fc--blue">{{translate('decking.info.tipodiposa')}}</p>
                                    <p v-if="$store.state.pose_direction" class="text--sm fc--blue mb-4">{{$store.state.pose_direction.description}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-12 text-center">
                                    <img :src="$store.state.tile_orientation.image" class="d-block w-100 h-auto mx-auto mb-0">
                                </div>
                                <div class="col-lg-8 text-start pt-3">
                                    <p class="text--md fw--bold fc--blue">{{translate('decking.summary.orientamento')}}</p>
                                    <p class="text--sm fc--blue mb-4">{{$store.state.tile_orientation.description}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-12 text-center">
                                    <img :src="$store.state.image_prodotto" class="d-block w-100 h-auto mx-auto mb-0">
                                </div>
                                <div class="col-lg-8 text-start pt-3">
                                    <p class="text--md fw--bold fc--blue">{{translate('decking.info.prodotto')}} {{$store.state.product.name}}</p>
                                    <p class="text--sm fc--blue mb-2">{{translate('decking.summary.portatafinoa')}} {{$store.state.product.pormax}} kg, {{translate('decking.summary.altezzada')}} {{$store.state.product.hmin/10}} cm</p>
                                </div>
                            </div>


                        </div> -->
                        <div class="col-lg-12 mt-5 mt-lg-0">

                            <div class="c-table-prod pb-3">
                                <!-- intestazione -->
                                <div class="row g-0 d-none d-lg-flex w-100">
                                    <div class="col-8">
                                        <div class="td">
                                            <p class="text--md text-uppercase fc--black fw--bold mt-4"></p>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="td">
                                            <p class="text--md text-uppercase fc--black fw-bold">{{translate('decking.info.qtynec')}}</p>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="td">
                                            <p class="text--md text-uppercase fc--black fw-bold">{{translate('decking.info.qtyord')}}</p>
                                        </div>
                                    </div>
                                </div>

                                <!-- materiale living eventuale -->
                                <div class="row g-0" v-if="$store.state.livingproduct">
                                    <div class="col-lg-1">
                                        <div class="td">
                                            <img :src="$store.state.livingproduct.image" class="mb-1 md:w-100 w-50 mx-auto d-block  object-fit-contain" />
                                        </div>
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="td">
                                            <p class="text--sm fc--black text-lg-start text-center">{{$store.state.livingproduct.codice}}</p>
                                            <p class="text--md fc--black text-lg-left text-center fw--bold">{{$store.state.livingproduct.descrizione}}</p>
                                            <p class="text--sm fc--black">Dim: {{$store.state.livingproduct.lunghezza}}mm x {{$store.state.livingproduct.larghezza}}mm x {{$store.state.livingproduct.spessore}}mm</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="td">
                                            <p class="text--md fc--black"><span class="d-md-none d-inline-block fw--bold">{{translate('decking.info.qtynec')}}: </span>{{livingproductsinfo[0]}} pz</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="td">
                                            <p class="text--md fc--black"><span class="d-md-none d-inline-block fw--bold">{{translate('decking.info.qtyord')}}: </span>{{livingproductsinfo[1]}} cf</p>
                                        </div>
                                    </div>
                                </div>

                                <div v-for="(product,index) in products" class="row g-0">
                                    <div class="col-lg-1">
                                        <div class="td">
                                            <img :src="product.image" class="mb-1 w-100 mx-auto d-block  object-fit-contain" />
                                        </div>
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="td">
                                            <p class="text--sm fc--black">{{product.obj.commercial_code}}</p>
                                            <p class="text--md fc--black fw--bold">{{product.obj.name}}</p>
                                            <p class="text--sm fc--black">{{translate('decking.product.altezzareg')}}: {{product.obj.regolazioneh}}</p>
                                            <p class="text--sm fc--black">{{translate('decking.product.diambase')}}: {{product.obj.diametrobase}}, {{translate('decking.fuga.title')}}: {{product.obj.fuga}} mm, {{translate('decking.summary.portata')}}: {{product.obj.portata}} kn</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="td">
                                            <p class="text--md fc--black"><span class="d-md-none d-inline-block fw--bold">{{translate('decking.info.qtynec')}}: </span>{{product.qty}} {{product.obj.um}}</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="td">
                                            <p class="text--md fc--black"><span class="d-md-none d-inline-block fw--bold">{{translate('decking.info.qtyord')}}: </span>{{product.cf}} {{translate('decking.product.conf')}}<br>(1 {{translate('decking.product.conf')}}, {{product.obj.qtainumv}} {{product.obj.um}})</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <!-- magatello -->
                            <div v-if="magatello" class="c-table-prod pb-3">
                                <div class="row mt-3 mb-1">
                                    <div class="col-12 text-center text-lg-start">
                                        <p class="text--lg text-uppercase fc--black fw--bold">{{translate('decking.info.magatello')}}</p>
                                    </div>
                                </div>

                                <div class="row g-0 d-none d-lg-flex w-100">
                                    <div class="col-8">
                                        <div class="td">
                                            <p class="text--md text-uppercase fc--black fw--bold"></p>
                                        </div>
                                    </div>

                                    <div class="col-2">
                                        <div class="td">
                                            <p class="text--md text-uppercase fc--black fw-bold">{{translate('decking.info.qtynec')}}</p>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="td">
                                            <p class="text--md text-uppercase fc--black fw-bold">{{translate('decking.info.qtyord')}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="magatello" class="row g-0">
                                    <div class="col-lg-1">
                                        <div  class="td">
                                            <div class="ratio ratio-1x1 w-50 mx-auto">
                                                <img :src="magatello.image" class="d-block w-100 object-fit-contain">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="td">
                                            <p class="text--md fc--black d-block">{{magatello.commercial_code}}</p>
                                            <p class="text--md fw--bold fc--black d-block mb-2">{{magatello.name}}</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="td">
                                            <p class="text--md fc--black"><span class="d-md-none d-inline-block fw--bold">{{translate('decking.info.qtynec')}}: </span>{{magatello.ml}} m</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="td">
                                            <p class="text--md fc--black"><span class="d-md-none d-inline-block fw--bold">{{translate('decking.info.qtyord')}}: </span>{{magatello.cf}} {{translate('decking.product.conf')}}<br>(1 {{translate('decking.product.conf')}}, {{magatello.qtainumv}} {{magatello.um}})</p>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="accessoriomagatello" class="row g-0">
                                    <div class="col-lg-1">
                                        <div  class="td">
                                            <div class="ratio ratio-1x1 w-50 mx-auto">
                                                <img :src="accessoriomagatello.image" class="d-block w-100 object-fit-contain">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="td">
                                            <p class="text--md fc--black d-block">{{accessoriomagatello.commercial_code}}</p>
                                            <p class="text--md fw--bold fc--black d-block mb-2">{{accessoriomagatello.name}}</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="td">
                                            <p class="text--md fc--black"><span class="d-md-none d-inline-block fw--bold">{{translate('decking.info.qtynec')}}: </span>{{accessoriomagatello.qty}} {{accessoriomagatello.um}}</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="td">
                                            <p class="text--md fc--black"><span class="d-md-none d-inline-block fw--bold">{{translate('decking.info.qtyord')}}: </span>{{accessoriomagatello.cf}} {{translate('decking.product.conf')}}<br>(1 {{translate('decking.product.conf')}}, {{accessoriomagatello.qtainumv}} {{accessoriomagatello.um}})</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- prolunghe -->
                            <div v-if="extensions.length > 0" class="c-table-prod pb-3">
                                <div class="row mt-3 mb-1">
                                    <div class="col-12 text-center text-lg-start">
                                        <p class="text--lg text-uppercase fc--black fw--bold">{{translate('decking.info.prolunghe')}}</p>
                                    </div>
                                </div>

                                <div class="row g-0 d-none d-lg-flex w-100">
                                    <div class="col-8">
                                        <div class="td">
                                            <p class="text--md text-uppercase fc--black fw--bold">{{translate('decking.info.prodotto')}}</p>
                                        </div>
                                    </div>

                                    <div class="col-2">
                                        <div class="td">
                                            <p class="text--md text-uppercase fc--black fw-bold">{{translate('decking.info.qtynec')}}</p>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="td">
                                            <p class="text--md text-uppercase fc--black fw-bold">{{translate('decking.info.qtyord')}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div v-for="(pro,index) in extensions" class="row g-0">
                                    <div class="col-lg-1">
                                        <div  class="td">
                                            <div class="ratio ratio-1x1 w-50 mx-auto">
                                                <img :src="pro.image" class="d-block w-100 object-fit-contain">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="td">
                                        <p class="text--lg text-uppercase fc--black fw--bold d-block d-lg-none">{{translate('decking.info.prodotto')}}</p>
                                            <p class="text--md fc--black d-block">{{pro.obj.commercial_code}}</p>
                                            <p class="text--md fw--bold fc--black d-block mb-2">{{pro.obj.name}}</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="td">
                                            <p class="text--lg text-uppercase fc--black fw--bold d-block d-lg-none">{{translate('decking.info.qtynec')}}</p>
                                            <p class="text--md fc--black"><span class="d-md-none d-inline-block fw--bold">{{translate('decking.info.qtynec')}}: </span>{{pro.qty}} {{pro.obj.um}}</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="td">
                                            <p class="text--lg text-uppercase fc--black fw--bold d-block d-lg-none">{{translate('decking.info.qtyord')}}</p>
                                            <p class="text--md fc--black"><span class="d-md-none d-inline-block fw--bold">{{translate('decking.info.qtyord')}}: </span>{{pro.cf}} {{translate('decking.product.conf')}}<br>(1 {{translate('decking.product.conf')}}, {{pro.obj.qtainumv}} {{pro.obj.um}})</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- accessories -->
                            <div v-if="accessori.length > 0 || livingaccessori.length > 0" class="c-table-prod pb-3">
                                <div class="row mt-3 mb-1">
                                    <div class="col-12">
                                        <p class="text--lg text-uppercase fc--black fw--bold">{{translate('decking.info.accessori')}}</p>
                                    </div>
                                </div>

                                <div class="row g-0 d-none d-lg-flex w-100">
                                    <div class="col-8">
                                        <div class="td">
                                            <p class="text--md text-uppercase fc--black fw--bold">{{translate('decking.info.prodotto')}}</p>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="td">
                                            <p class="text--md text-uppercase fc--black fw-bold">{{translate('decking.info.qtynec')}}</p>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="td">
                                            <p class="text--md text-uppercase fc--black fw-bold">{{translate('decking.info.qtyord')}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div v-for="(acc,index) in livingaccessori" class="row g-0 mb-5 mb-lg-0">
                                    <div class="col-lg-1">
                                        <div class="td">
                                            <div class="ratio ratio-1x1 w-50 mx-auto">
                                                <img :src="acc.image" class="d-block w-100 object-fit-contain">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="td">
                                            <p class="text--lg text-uppercase fc--black fw--bold d-block d-lg-none">{{translate('decking.info.accessori')}}</p>
                                            <p class="text--md fc--black">{{acc.codice}}</p>
                                            <p class="text--md fw--bold fc--black">{{acc.nome}}</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="td">
                                            <p class="text--lg text-uppercase fc--black fw--bold d-block d-lg-none">{{translate('decking.info.qtynec')}}</p>
                                            <p class="text--md fc--black"><span class="d-md-none d-inline-block fw--bold">{{translate('decking.info.qtynec')}}: </span>{{acc.qtynecessaria}} {{acc.umcf}}</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="td">
                                            <p class="text--lg text-uppercase fc--black fw--bold d-block d-lg-none">{{translate('decking.info.qtyord')}}</p>
                                            <p class="text--md fc--black"><span class="d-md-none d-inline-block fw--bold">{{translate('decking.info.qtyord')}}: </span>{{acc.confezioni}} {{acc.um}}<br>(1 {{acc.um}}, {{acc.qtaconfezione}} {{acc.umcf}})</p>
                                        </div>
                                    </div>
                                </div>

                                <div v-for="(acc,index) in accessori" class="row g-0 mb-5 mb-lg-0">
                                    <div class="col-lg-1">
                                        <div class="td">
                                            <div class="ratio ratio-1x1 w-50 mx-auto">
                                                <img :src="acc.image" class="d-block w-100 object-fit-contain">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="td">
                                            <p class="text--lg text-uppercase fc--black fw--bold d-block d-lg-none">{{translate('decking.info.accessori')}}</p>
                                            <p class="text--md fc--black">{{acc.obj.commercial_code}}</p>
                                            <p class="text--md fw--bold fc--black">{{acc.obj.name}}</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="td">
                                            <p class="text--lg text-uppercase fc--black fw--bold d-block d-lg-none">{{translate('decking.info.qtynec')}}</p>
                                            <p class="text--md fc--black"><span class="d-md-none d-inline-block fw--bold">{{translate('decking.info.qtynec')}}: </span>{{acc.qty}} {{acc.obj.um}}</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="td">
                                            <p class="text--lg text-uppercase fc--black fw--bold d-block d-lg-none">{{translate('decking.info.qtyord')}}</p>
                                            <p class="text--md fc--black"><span class="d-md-none d-inline-block fw--bold">{{translate('decking.info.qtyord')}}: </span>{{acc.cf}} {{translate('decking.product.conf')}}<br>(1 {{translate('decking.product.conf')}}, {{acc.obj.qtainumv}} {{acc.obj.um}})</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
    import axios from 'axios';
    import {
        required,
        minLength
    } from 'vuelidate/lib/validators';
    export default {
        name: 'MySystem',
        data: function() {
            return {
                livingproductsinfo: {

                },
                products:{

                },
                magatello:{

                },
                accessoriomagatello:{

                },
                qty:null,
                extensions: [],
                accessori: [],
                proposalid: 0

            }
        },
        methods: {
            setSystem(sy){
                this.$store.commit('setSystem', sy);
            },
            prevStep(){
                return 'pdf';
            },
            nextStep(){
                return null;
            },
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode != 43 && charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            titleposa() {
                if (this.$store.state.pose_type.id == 1) {
                    return 'Paving';
                } else {
                    return 'Decking';
                }
            }
        },
        beforeMount: function() {
            this.$eventBus.$emit('SET-LOADER',true);
            let qty;
            let ml;
            if ( (this.$store.state.pose_type.id == 1 && this.$store.state.magatello.id == 1) || this.$store.state.pose_type.id == 0 ) {
                qty = this.$store.state.calcs.supports;
                ml = this.$store.state.calcs.ml;
            } else {
                qty = this.$store.state.pose_direction.qty;
                ml = 0;
            }
            let hok;
            if(this.$store.state.tile[2].id=="h") {
                hok=this.$store.state.area.height_a-this.$store.state.tile[2].value; // altezza in caso di 2 lati
            } else {
                hok=this.$store.state.area.height_a-this.$store.state.tile[4].value; // altezza in caso di 4 lati
            }
            let fuga;
            fuga=this.$store.state.leak;
            let toSend = {};
            toSend.matrice = this.$store.state.calcs.matrice;
            toSend.livingproduct = this.$store.state.livingproduct;
            toSend.ls = this.$store.state.area.area_sizes[0].value;
            toSend.ps = this.$store.state.area.area_sizes[1].value;
            toSend.idmagatello = this.$store.state.magatello.id;
            axios.post('/api/products/list/'+this.$store.state.product.id+'/'+hok+'/'+qty+'/'+ml+'/'+fuga, toSend).then(response => {
                this.proposalid = response.data.proposal;
                this.products = response.data.products;
                this.magatello = response.data.magatello;
                this.accessoriomagatello = response.data.accessoriomagatello;
                this.livingproductsinfo = response.data.livingproductsinfo;
                if (response.data.livingaccessori) {
                    this.livingaccessori = response.data.livingaccessori;
                }
                if (response.data.prolunghe) {
                    this.extensions = response.data.prolunghe;
                }
                if (response.data.accessori) {
                    this.accessori = response.data.accessori;
                }

                this.$store.commit('setProposalId', response.data.proposal);
                this.$store.commit('setImageProdotto', response.data.imageprodotto);
                this.$store.commit('setExtensions',response.data.prolunghe);
                this.$store.commit('setAccessories',response.data.accessori);
                this.$store.commit('setLivingAccessories',response.data.livingaccessori);
                this.$store.commit('setMatrice', response.data.matrice);
                this.$store.commit('setInfoMagatello', response.data.magatello);

                this.setSystem(this.products);
                this.$eventBus.$emit('SET-LOADER',false);
            }).catch(e => {
                this.$eventBus.$emit('SET-LOADER',false);
                this.$eventBus.$emit('ALERT-ERROR','Errore di rete.');
            });

        }
    }
</script>
