<template>
    <BaseLayout :titleposa="titleposa()" :title="$attrs.title" :subtitle="$attrs.subtitle" :steps="$attrs.steps" :variant="'default'" :prev='prevStep()' :next="nextStep()">

        <div class="w-100 form-template-1 h-100 d-flex flex-column">
            <div class="row h-100 scroll">
                <div class="col-12 d-flex align-items-center">
                    <div class="w-100 row">
                        <div @click="setActivePose(pt)" v-for="pt in pose_types" class="d-block col-lg-6 h-auto text-center mb-5 mb-lg-0" :class="pt.disabled ? 'disabled-section' : null">
                            <div class="sm:px-0 px-2">
                                <img v-if="!$store.state.pose_type || pt.id != $store.state.pose_type.id" :src="pt.image" class="d-block w-75 sm:w-100 mx-auto h-auto px-lg-5 py-lg-0">
                                <img v-if="$store.state.pose_type && pt.id == $store.state.pose_type.id" :src="pt.image_active" class="d-block w-75 sm:w-100 mx-auto h-auto px-lg-5 py-lg-0">
                                <p class="text--xl fw--bold text-uppercase fc--black mt-3 mb-3">{{translate(pt.title)}}</p>
                                <p class="text--md fc--black">{{translate(pt.description)}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </BaseLayout>
</template>

<script>
    import axios from 'axios';
    export default {
        name: 'SelectPose',
        data: function() {
            return {
                magatelli: [
                        {
                            id: 8890,
                            description: this.translate('decking.keradeck.title'),
                            subtitle: this.translate('decking.keradeck.description'),
                            image: require('@/assets/images/png/decking-off.png'),
                            image_active: require('@/assets/images/png/decking-on.png'),
                            type:'magatello'
                        },
                        {
                            id: 8891,
                            description: this.translate('decking.magnelis.title'),
                            subtitle: this.translate('decking.magnelis.description'),
                            image: require('@/assets/images/png/decking-off.png'),
                            image_active: require('@/assets/images/png/decking-on.png'),
                            type:'magatello'
                        },
                        {
                            id: 8892,
                            description: this.translate('decking.classico.title'),
                            subtitle: this.translate('decking.classico.description'),
                            image: require('@/assets/images/png/decking-off.png'),
                            image_active: require('@/assets/images/png/decking-on.png'),
                            type:'magatello'
                        }
                    ],
                pose_types: [
                    {
                        id: 0,
                        title: 'decking.selectpose.deckingtitle',
                        description: 'decking.selectpose.deckingtext',
                        image: require('@/assets/images/png/decking-off.png'),
                        image_active: require('@/assets/images/png/decking-on.png'),
                        disabled:false
                    },
                    {
                        id: 1,
                        title: 'decking.selectpose.pavingtitle',
                        description: 'decking.selectpose.pavingtext',
                        image: require('@/assets/images/png/paving-off.png'),
                        image_active: require('@/assets/images/png/paving-on.png'),
                        disabled:false
                    }
                ],
                magatellofisso: {
                            id: 8890,
                            description: this.translate('decking.keradeck.title'),
                            subtitle: this.translate('decking.keradeck.description'),
                            image: require('@/assets/images/png/decking-off.png'),
                            image_active: require('@/assets/images/png/decking-on.png'),
                            type:'magatello'
                        }

            }
        },
        beforeMount: function() {
            if (!this.$store.state.pose_type) {
                this.setActivePose(this.pose_types[1]);
            }
            this.$eventBus.$emit('SET-LOADER',true);
            let toSend = {};

            axios.post('/api/products/defaultData', toSend).then(response => {
                console.dir(response);
                this.$store.commit('setFughe', response.data.fughe);
                this.$store.commit('setMagatelli', response.data.magatelli);
                this.$eventBus.$emit('SET-LOADER',false);
            }).catch(e => {
                this.$eventBus.$emit('SET-LOADER',false);
                this.$eventBus.$emit('ALERT-ERROR','Network error');
            });

        },
        methods: {
            setMagatello(magatello){
                this.$store.commit('setMagatello', magatello);
            },
            setActivePose(pt){
                this.$store.commit('setPoseType', pt);
            },
            prevStep(){
                return null;
            },
            nextStep(){
                if (this.$store.state.pose_type.id == 1) {
                    // aggiornare gli step
                    return 'select-magatello';
                } else {
                    // aggionare gli step
                    // imposto un magatello altrimenti i calcoli successivi non vanno
                    this.setMagatello(this.magatelli[0]);
                    return 'select-floor';
                }
            },
            titleposa() {
                if (this.$store.state.pose_type.id == 1) {
                    return 'Paving';
                } else {
                    return 'Decking';
                }
            },
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode != 43 && charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            }
        }
    }
</script>
