<template>
  <BaseLayout :titleposa="titleposa()" :title="$attrs.title" :subtitle="$attrs.subtitle" :steps="$attrs.steps" :variant="'default'"  :prev='prevStep()' :next="nextStep()">
      <div class="w-100 form-template-1 h-100 d-flex flex-column">
          <div class="row h-100 scroll">

              <div class="col-12 align-items-center" v-if="tipoposa == 0">
                <div class="row mb-3"  v-for="(mm,index) in livingproducts">
                    <div class="col-12" v-if="mm.material_type.length > 0"><p class="text--lg text-uppercase fw--bold">{{ mm.name }}</p></div>
                    <div class="col-12" v-if="mm.material_type.length > 0">
                        <div class="w-100 row" v-for="(mt,index2) in mm.material_type">
                            <div class="col-12"><p class="text--md text-uppercase fw--bold">{{ mt.name }}</p></div>
                            <div v-for="(pt,index3) in mt.products" class="col-lg-4 order-2 order-lg-1 my-1" style="cursor:pointer;" v-if="pt.conf_raisedfloor" >
                                <div @click="setLivingProduct(pt)" class="row livingproduct" :class="isLivingProductSelected(pt) ? 'active' : 'notactive'">
                                    <div class="col-lg-2"><img :src="pt.image" class="d-block w-100"></div>
                                    <div class="col-lg-10">
                                        <p class="text--xs">{{ pt.codice }}</p>
                                        <p class="text--md">{{ pt.descrizione }}</p>
                                        <p class="text--xs">Dim.: {{ pt.lunghezza }}mm x {{ pt.larghezza }}mm x {{ pt.spessore }}mm</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>

              <div class="col-12 d-flex align-items-center" v-if="tipoposa == 1">
                    <div class="w-100 row">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-12 order-2 order-lg-1">
                                    <div class="form-template-1">
                                        <div class="row">
                                            <div class="mb-5 col-lg-4" v-for="size in tile_sizes">
                                                <label class="text--lg fw--bold text-uppercase" for="">{{translate(size.name)}}</label>
                                                <p class="text--sm  mb-2">{{translate('decking.info.latoinmm')}}</p>
                                                <div class="d-flex">
                                                    <input @click="setSelectedSize(size)" @keypress="isNumber($event)" type="number" min="1" style="width: 70% !important;" class="c-input text--md d-block col-12 col-lg-5" :class="[isSizeSelected(size) ? 'active' : '']" v-model="size.value" name="value" value="">
                                                    <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                                        <p class="text--sm fw--bold fc--blue px-1">mm</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="offset-lg-2 col-lg-8 order-lg-2 order-1">
                                    <div class="w-100 d-flex align-items-center justify-content-center">
                                        <div class="tile-letters-box ratio ratio-1x1 position-relative w-100 h-50">
                                            <img :src="require('@/assets/images/svg/tile.svg')" class="d-block w-100 h-75">
                                            <div v-for="size in tile_sizes" :style="getLetterPosition(size)" class="tile-letters-box--letter" :class="[isSizeSelected(size) ? 'active' : '']">
                                                {{size.id}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" v-if="this.$store.state.pose_type.id == 1">
                            <div class="row">
                                <div class="col-lg-12 order-2 order-lg-2">
                                  <div class="row">
                                      <div class="col-12">
                                          <label class="text--lg mb-0 fw--bold text-uppercase" for="">{{translate('decking.fuga.title')}} F</label>
                                      </div>
                                      <div class="col-lg-12">
                                          <p class="text--sm  mb-2">{{translate('decking.fuga.text')}}</p>
                                          <div class="d-flex w-50">
                                              <select class="text--md d-block w-100 c-input" @change="setLeak($event)" v-model="leak">
                                                  <option v-for="item in this.$store.state.fughetenant" :value="item" v-bind:selected="$index === 0 ? 'selected' : ''">{{item}}</option>
                                              </select>
                                              <div class="d-inline-flex p-1 bg--white border border-primary border-start-0 align-items-center">
                                                  <p class="text--sm fw--bold fc--blue px-1">mm</p>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-lg-10">
                                          <img src="@/assets/images/svg/fuga.svg" class="d-block h-75 w-100 mt-3">
                                          <div style="top: 63%; left: 74%;" class="tile-letters-box--letter">F</div>
                                      </div>
                                  </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
      </div>
  </BaseLayout>
</template>

<script>
  import axios from 'axios';
  import {
      required,
      minLength
  } from 'vuelidate/lib/validators';
  export default {
      name: 'SelectMaterial',
      data: function() {
          return {
                selected_size:null,
                tile_sizes: [
                    {id:'a', name:this.translate('decking.lato.a'), value:(this.$store.state.pose_type.id == 1) ? (this.$store.state.tile && this.$store.state.tile[0]) ? this.$store.state.tile[0].value : 600 : this.livingproducta},
                    {id:'b', name:this.translate('decking.lato.b'), value:(this.$store.state.pose_type.id == 1) ? (this.$store.state.tile && this.$store.state.tile[1]) ? this.$store.state.tile[1].value : 600 : this.livingproductb},
                    {id:'h', name:this.translate('decking.tile.spessore'), value:(this.$store.state.pose_type.id == 1) ? (this.$store.state.tile && this.$store.state.tile[2]) ? this.$store.state.tile[2].value : 20 : this.livingproducth}
                ],
                livingproduct:null,
                livingproducta:null,
                livingproductb:null,
                livingproducth:null,
                livingproducts:[],
                tipoposa: this.$store.state.pose_type.id,
                leak: (this.$store.state.leak) ? this.$store.state.leak : this.$store.state.fughetenant[1]
          }
      },
      methods: {
        setLeak(event){
            this.leak = event.target.value;
            this.$store.commit('setLeak', event.target.value);
            console.log(event.target.value);
        },
        getLetterPosition(size){
            switch (size.id) {
                case 'a':
                    return 'top:23%; left:24%;';
                    break;
                case 'b':
                    return 'top:25%; left:64%;';
                    break;
                case 'h':
                    return 'top:50%; left:26%;';
                    break;
            }
        },
        setSelectedSize(size){
            this.$store.commit('setTile', this.tile_sizes);
            // this.selected_size = size;
        },
        isSizeSelected(size){
            if (this.selected_size && this.selected_size.id) {
                if (size.id == this.selected_size.id) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return  false;
            }
        },
        isLivingProductSelected(product){
            if (this.livingproduct) {
                if (product.id == this.livingproduct) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return  false;
            }
        },
        setLivingProduct(pt){
            this.livingproduct = pt.id;
            this.tile_sizes[0].value = pt.lunghezza;
            this.tile_sizes[1].value = pt.larghezza;
            this.tile_sizes[2].value = pt.spessore;
            this.$store.commit('setLivingProduct', pt);
            this.$store.commit('setTile', this.tile_sizes);
        },
        prevStep(){
            // TODO: materialitenant che ho creato partendo da magatellitenant.... dobbiamo fare la parte
            // di scelta dei materiali visibili per quel cliente
            if (this.$store.state.pose_type.id == 1) {
                return 'select-magatello';
            } else {
                return 'select-pose';
            }
        },
        nextStep(){
            if (this.$store.state.pose_type.id == 1) {
                return 'select-orientationanddisposition';
            } else {
                return 'select-orientationanddisposition';
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode != 43 && charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        titleposa() {
                if (this.$store.state.pose_type.id == 1) {
                    return 'Paving';
                } else {
                    return 'Decking';
                }
            }
      },
      beforeMount: function() {
        console.log("before mount");
            this.$eventBus.$emit('SET-LOADER',true);
            let toSend = {};

            axios.post('/api/products/livingmaterials', toSend).then(response => {
                // console.dir(response.data.prodotti);
                this.livingproducts = response.data.materiali;
                this.$store.commit('setProdotti', response.data.materiali);
                this.$eventBus.$emit('SET-LOADER',false);
            }).catch(e => {
                this.$eventBus.$emit('SET-LOADER',false);
                this.$eventBus.$emit('ALERT-ERROR','Network error');
            });
            if (!this.$store.state.tile && this.$store.state.pose_type.id == 1) {
                this.$store.commit('setTile', this.tile_sizes);
            }
      },
      mounted: function() {
        console.log("mounted");
        if (!this.$store.state.leak) {
            this.$store.commit('setLeak', this.leak);
        }
      },
      watch: {
        'tile_sizes': {
            handler: function(oldVal, newVal) {
                console.log("in watch");
                this.$store.commit('setTile', newVal);
            }
        }
        // 'leak': {
        //         handler: function(oldVal, newVal) {
        //             this.$store.commit('setLeak', newVal);
        //         },
        //         deep: true
        //     }
      }
  }
</script>
