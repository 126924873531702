<template>
    <BaseLayout :titleposa="titleposa()" :title="$attrs.title" :subtitle="$attrs.subtitle" :steps="$attrs.steps" :variant="'default'"  :prev='prevStep()' :next="nextStep()">
        <div class="w-100 form-template-1 h-100 d-flex flex-column">
            <div class="row h-100 scroll">
                <div class="col-12 d-flex align-items-center">
                    <div class="row">
                        <div class="col-12">
                            <div class="w-100 row">
                                <div @click="setActiveForm(frms)" v-for="frms in forms" v-if="frms.active" class="col-lg-6 px-5 mb-5 mb-lg-0 text-center">
                                    <img v-if="!$store.state.form || frms.id != $store.state.form.id" :src="frms.image" class="d-block w-75 sm:w-100 mx-auto h-auto px-lg-5">
                                    <img v-if="$store.state.form && frms.id == $store.state.form.id" :src="frms.image_active" class="d-block w-75 sm:w-100 mx-auto h-auto px-lg-5">
                                    <p class="text--lg fc--black text-uppercase fw--bold mb-3 mt-3">{{translate(frms.description)}}</p>
                                    <p class="text--md fc--black mb-1 mt-2">{{translate(frms.description_text)}}</p>
                                </div>
                                <div v-for="frms in forms" v-if="!frms.active" class="col-lg-6 px-5 mb-5 mb-lg-0 text-center" style="opacity: 0.4">
                                    <img v-if="!$store.state.form || frms.id != $store.state.form.id" :src="frms.image" class="d-block w-75 sm:w-100 mx-auto h-auto px-lg-5">
                                    <img v-if="$store.state.form && frms.id == $store.state.form.id" :src="frms.image_active" class="d-block w-75 sm:w-100 mx-auto h-auto px-lg-5">
                                    <p class="text--lg fc--black text-uppercase fw--bold mb-3 mt-3">{{translate(frms.description)}}</p>
                                    <p class="text--md fc--black mb-1 mt-2">{{translate(frms.description_text)}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>

<script>
    import axios from 'axios';
    import {
        required,
        minLength
    } from 'vuelidate/lib/validators';
    export default {
        name: 'AreaForm',
        data: function() {
            return {
                //FORMS
                forms: [
                    {
                        id: 0,
                        title: 'A.',
                        description: this.translate('decking.areatype.rettangolaretitle'),
                        description_text: this.translate('decking.areatype.rettangolaretext'),
                        image: require('@/assets/images/svg/form-rect-off.svg'),
                        image_active: require('@/assets/images/svg/form-rect-on.svg'),
                        active: true
                    },
                    {
                        id: 1,
                        title: 'C.',
                        description: this.translate('decking.areatype.elletitle'),
                        description_text: this.translate('decking.areatype.elletext'),
                        image: require('@/assets/images/svg/form-l-off.svg'),
                        image_active: require('@/assets/images/svg/form-l-on.svg'),
                        active: true
                    },
                ],

            }
        },
        mounted: function() {
            if (!this.$store.state.form) {
                this.$store.commit('setForm', this.forms[0]);
            }
        },
        methods: {
            setActiveForm(frms){
                this.$store.commit('setForm', frms);
            },
            prevStep(){
                return 'select-orientationanddisposition';
            },
            nextStep(){
                return 'area-size';
            },
            titleposa() {
                if (this.$store.state.pose_type.id == 1) {
                    return 'Paving';
                } else {
                    return 'Decking';
                }
            }
        }
    }
</script>
